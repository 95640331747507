import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { Button, Col, Form, Input, Label, Modal, ModalBody, Row, Spinner } from 'reactstrap'
import ReactSelect from 'react-select'
import Flatpickr from 'react-flatpickr';
import { customSelectStyles, selectThemeColors } from '../../modules/commonModel/Utils';
import { InvoiceService } from '../../modules/Services/Invoice';
import toast from 'react-hot-toast';
import moment from 'moment';

function InvoicePayment({ paymentModal, setPaymentModal, invoiceId, render, setRender, currentCustomer, setRenderElementService }) {
    const { control, handleSubmit, reset, formState: { errors } } = useForm({
                                                                                defaultValues: {
                                                                                    payment_date: new Date(),
                                                                                    payment_method: '',
                                                                                    payment_amount: '',
                                                                                    payment_note: ''
                                                                                }
                                                                            });
    const entity_id = localStorage.getItem('entity_id')
    const [loader, setLoader] = useState(null)
    const [methodOption, setMethodOption] = useState(null)

    function getPaymentSatus() {
        InvoiceService.getPaymentStatusList(entity_id)
        .then((res: any) => {
            const data = res.data?.map((item: any) => {
                return { value: item?.id, label: item?.name }
            })
            setMethodOption(data)
        })
        .catch((err: any) => {
            toast.error(err?.response?.data?.message)
        })
    }

    const onSubmit = (data: any) => {
        setLoader('create')
        const payload = {
            entity_id: entity_id,
            invoice_id: invoiceId,
            customer_id: currentCustomer?.value,
            date: data?.payment_date ? moment(data?.payment_date[0]).format('YYYY-MM-DD') : '',
            method_id: data?.payment_method?.value,
            amount: data?.payment_amount,
            notes: data?.payment_note
        }

        InvoiceService.createPayment(entity_id, payload)
        .then((res: any) => {
            toast.success(res?.data?.message)
            setPaymentModal(false)
            setRender(!render)
            setRenderElementService(1)
        })
        .catch((err: any) => {
            toast.error(err?.response?.data?.message)
        })
        .finally(() => setLoader(null))
    }

    useEffect(() => {
        if (paymentModal) getPaymentSatus()
        else reset({})
    }, [paymentModal])

    return (
        <Modal isOpen={paymentModal} className="modal-dialog-centered modal-lg" toggle={() => setPaymentModal(false)} >
            <ModalBody className="px-sm-5">
                <Row className='my-5'>
                    <h3 className='d-flex justify-content-center'>Add Payment</h3>
                </Row>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row className='p-5 pb-0'>
                        <Col md='4' className='d-flex'>
                            <Label className='d-flex align-items-center m-0 me-6'>Date</Label>
                            <Controller name='payment_date' control={control} rules={{ required: true }}
                                render= {({ field }) => (
                                    <Flatpickr
                                        {...field}
                                        className='form-control'
                                        placeholder='Select Date'
                                        options={{
                                            enableTime: false,
                                            altInput: true,
                                            dateFormat: 'Y-m-d H:i',
                                            disableMobile: true
                                        }}
                                    />
                                )}
                            />
                        </Col>
                        <Col md='4' className='d-flex'>
                            <Label className='d-flex align-items-center m-0 me-3'>Method</Label>
                            <Controller name='payment_method' control={control} rules={{ required: true }}
                                render= {({ field: { onChange } }) => (
                                    <ReactSelect
                                        isClearable={false}
                                        className='react-select w-100'
                                        classNamePrefix='select'
                                        placeholder='Select Status'
                                        name='callback-react-select'
                                        styles={customSelectStyles(errors.payment_method)}
                                        theme={selectThemeColors}
                                        options={methodOption}
                                        onChange={(data) => {
                                            onChange(data)
                                        }}
                                    />
                                )}
                            />
                        </Col>
                        <Col md='4' className='d-flex'>
                            <Label className='d-flex align-items-center m-0 me-3'>Amount</Label>
                            <Controller name='payment_amount' control={control} rules={{ required: true }}
                                render= {({ field }) => (
                                    <Input {...field} type='number' placeholder='Enter Amount' 
                                        style={{border: errors.payment_amount && '1px solid #ea5455'}} 
                                    />
                                )}
                            />
                        </Col>
                    </Row>
                    <Row className='ps-5'>
                        <Col md='4'>
                            {errors?.payment_date && (<small className="text-danger ms-16">Date is a required field</small>)}
                        </Col>
                        <Col md='4'>
                            {errors?.payment_method && (<small className="text-danger ms-16">Method is a required field</small>)}
                        </Col>
                        <Col md='4'>
                            {errors?.payment_amount && (<small className="text-danger ms-16">Amount is a required field</small>)}
                        </Col>
                    </Row>
                    <Row className='p-5'>
                        <Col md={12} className='d-flex'>
                            <Label className='form-label me-3' for='notes'>Notes</Label>
                            <Controller name='payment_note' control={control} rules={{ required: false }}
                                render= {({ field }) => (
                                    <Input type='textarea' rows='2' placeholder='Enter Notes' {...field} />
                                )}
                            />
                        </Col>
                    </Row>
                    <Row className='p-5'>
                        <div className='d-flex justify-content-end'>
                            <div>
                                <Button type="button" size='sm' color="secondary" className='me-2' onClick={() => setPaymentModal(false)}>
                                    Cancel
                                </Button>
                                <Button type='submit' size='sm' disabled={loader === 'create'} color='primary'>
                                    {loader === 'create' ? <Spinner size='sm' /> : <span className='align-middle d-sm-inline-block d-none'>Add</span>}
                                </Button>
                            </div>
                        </div>
                    </Row>
                </Form>
            </ModalBody>
        </Modal>
    )
}

export default InvoicePayment