import React, { Fragment, useState } from 'react';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from 'reactstrap';
import ReactSelect from 'react-select';
import { customSelectStyles, selectThemeColors } from '../../modules/commonModel/Utils';
import toast from 'react-hot-toast';
import { JobLegsService } from '../../modules/Services/JobLegs';
import moment from 'moment';

function ChangeJobStatus({
                            openJobStatus,
                            setOpenJobStatus,
                            statusData,
                            changeCheckbox,
                            selectAll,
                            setRenderElementService,
                            currentCustomer,
                            statusIds,
                            currentDriver,
                            jobDate,
                            filter_tag,
                            searchTerm
}) {

    const entity_id = localStorage.getItem('entity_id');
    const [loader, setLoader] = useState(false)
    const [currentJobStatus, setCurrentJobStatus] = useState({ value: null, label: 'Select Status' })

    function updateStatus() {
        setLoader(true)
        let payload: any = {
            job_ids: selectAll?.all ? 'all' : changeCheckbox,
            status: currentJobStatus?.value
        }

        if (selectAll?.all) {
            payload = {
                ...payload,
                customer: currentCustomer?.value ? currentCustomer?.value : null,
                filter_status: statusIds?.length ? statusIds : null,
                driver: currentDriver?.value ? currentDriver?.value : null, 
                start_date : jobDate?.length ? (jobDate[0] ? moment(jobDate[0]?.toISOString()).format("YYYY-MM-DD") : null) : null,
                end_date : jobDate?.length ? (jobDate[1] ? moment(jobDate[1]?.toISOString()).format("YYYY-MM-DD") : null ): null,
                filter_tag: filter_tag?.value ? filter_tag?.value : null,
                search: searchTerm ? searchTerm : null
            }
        }

        JobLegsService.updateJobStatus(entity_id, payload)
        .then((res: any) => { 
            setRenderElementService(1)
            setOpenJobStatus(false)
            toast.success(res?.data?.message)
        })
        .catch((err: any) => {
            toast.error(err?.response?.data?.message)
        })
        .finally(() => setLoader(false))
    }

    return (
        <Fragment>
            <Modal isOpen={openJobStatus} toggle={() => setOpenJobStatus(false)} className='modal-dialog-centered model-lg'>
                <ModalBody>
                    <Row className='mt-10'>
                        <Col sm={12} className='d-flex'>
                            <span className='d-flex align-items-center me-5'>Update Status to</span>
                            <Col md={6}>
                                <ReactSelect
                                    isClearable={false}
                                    isSearchable={false}
                                    className='react-select w-100 text-start'
                                    classNamePrefix='select'
                                    theme={selectThemeColors}
                                    styles={customSelectStyles(null)}
                                    options={statusData}
                                    value={currentJobStatus}
                                    onChange={(data: any) => {
                                        setCurrentJobStatus(data)
                                    }}
                                />
                            </Col>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <div className="d-flex justify-content-end w-100">
                        <Button color="secondary" onClick={() => setOpenJobStatus(false)}>Cancel</Button>
                        <Button color="primary" type='submit' className="ms-2" disabled={loader} onClick={updateStatus}>
                            {loader ? <Spinner size='sm' /> : 'Update'}
                        </Button>
                    </div>
                </ModalFooter>
            </Modal>
        </Fragment>
    )
}

export default ChangeJobStatus