import { Controller, useFieldArray, useForm } from "react-hook-form"
import { Button, Col, Form, Input, Label, Modal, ModalBody, ModalFooter, Row, Spinner } from "reactstrap"
import AsyncCreatableSelect from 'react-select/async-creatable';
import { LocationService } from "../../modules/Services/Location";
import { customSelectStyles, preventDefualt, preventOnEnter, selectThemeColors } from "../../modules/commonModel/Utils";
import moment from "moment";
import Flatpickr from 'react-flatpickr'
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { JobService } from "../../modules/Services/Job";
import AsyncSelect from 'react-select/async'
import { DriverService } from "../../modules/Services/Drivers";
import { VehicleService } from "../../modules/Services/Vehicles";

function AddRecurringJob({ openRecurringJobModal, setOpenRecurringJobModal, setRenderElementService, recurringJobId, bookingId, appendRecurringJobLeg, fetchFormData, priceType, recurring_job_settings, setRecurring_job_settings }) {
    const { control, handleSubmit, reset, getValues, setValue, formState: { errors } } = useForm()
    const entity_id = localStorage.getItem('entity_id');
    const [checkedDay, setCheckedDay] = useState([])
    const [loader, setLoader] = useState(false)
    const [return_leg, setReturn_leg] = useState(false)
    const [preventDriver, setPreventDriver] = useState([])
    const [preventVehicle, setPreventVehicle] = useState([])
    let locationTimeoutId: ReturnType<typeof setTimeout> | null = null;
    let driverTimeoutId: ReturnType<typeof setTimeout> | null = null;
    let vahicleTimeoutId: ReturnType<typeof setTimeout> | null = null;
    
    const defaultDriver = {label : 'Select Driver', value : null}
    const defaultVehicle = {label : 'Select Vehicle', value : null}
    const defaultAllocationForm = {
        driver : defaultDriver,
        vehicle : defaultVehicle
    }

    const { fields, append, remove } = useFieldArray({
        control,
        name: "allocation"
    });
  
    const styles = {
        option : (baseStyles: any, state: any) => ({
            ...baseStyles,
            backgroundColor : state.isDisabled ? '#e6e6e6' : '',
            color : state.isDisabled ? '#b9b9c3' : '',
            cursor : state.isDisabled ? 'not-allowed' : ''
        })
    }

    const loadLocationOptions = (inputValue: any, callback: any) => {
        if (locationTimeoutId) clearTimeout(locationTimeoutId);

        locationTimeoutId = setTimeout(() => {
            const obj = { 
                name : inputValue
            }
            LocationService.getSuggestLocationList(entity_id, obj)
            .then((res: any) => {
                callback(res?.data?.response?.map((item: any) => {
                return { value: item?.location_name, label: item?.location_name }
                }))
            })
            .catch((err: any) => console.log(err?.response?.data?.message))
        }, 1000)
    }

    const submitRecurring = (data: any) => {
        setLoader(true)
        const allcationData = data?.allocation?.map((item: any) => {
            return { driver_id: item?.driver?.value || null, vehicle_id: item?.vehicle?.value || null, driver_vehicles_id: item?.driver_vehicles_id || null }
        })

        fetchFormData()
        .then(() => {
            const obj = {
                entity_id: entity_id,
                booking_id: bookingId,
                job_id: recurringJobId,
                pick_up_location: data?.pick_up_location?.value,
                drop_off_location: data?.drop_off_location?.value,
                return_pick_up_location: data?.return_pick_up_location?.value,
                return_drop_off_location: data?.return_drop_off_location?.value,
                start_at_date: data?.start_at_date,
                start_at_time: data?.start_at_time,
                return_start_at_time: data?.return_start_at_time,
                end_at_date: data?.end_at_date,
                end_at_time: data?.end_at_time,
                return_end_at_time: data?.return_end_at_time,
                pax: data?.pax,
                vehicles_required: data?.vehicles_required,
                selected_days: checkedDay,
                return_leg: return_leg,
                cost: data?.charge,
                driver_vehicles_arr: allcationData
            }
    
            return JobService.addRecurringJob(entity_id, obj);
        })
        .then((res: any) => {
            toast.success(res?.data?.message)
            setOpenRecurringJobModal(false)
            setRenderElementService(1)
        })
        .catch((err: any) => {
            toast.error(err?.response?.data?.message)
        })
        .finally(() => setLoader(false))
    }

    const recurringJobLeg = (data: any) => {
        const recurringData = data;
        recurringData.selected_days = checkedDay;
        recurringData.return_leg = return_leg;
        setRecurring_job_settings(data)
        appendRecurringJobLeg(data)
        setOpenRecurringJobModal(false)
    }

    useEffect(() => {
        if (openRecurringJobModal) {
            if (bookingId) {

                const selAllocationDriver = []
                const selAllocationVehicle = []
                const arr = recurring_job_settings?.allocation
                const allocationFormResArr = arr?.map((item: any, i: any) => {
                  selAllocationDriver.push({label : item?.driver?.label, value : item?.driver?.value})
                  selAllocationVehicle.push({label : item?.vehicle?.label, value : item?.vehicle?.value})
                  return {
                    driver : item?.driver ? {label : item?.driver?.label, value : item?.driver?.value } : defaultDriver,
                    vehicle : item?.vehicle ? {label : item?.vehicle?.label, value: item?.vehicle?.value} : defaultVehicle,
                  }
                })
                setPreventDriver(selAllocationDriver)
                setPreventVehicle(selAllocationVehicle)

                const data = {
                    pick_up_location: recurring_job_settings?.pick_up_location,
                    start_at_time: recurring_job_settings?.start_at_time,
                    drop_off_location: recurring_job_settings?.drop_off_location,
                    end_at_time: recurring_job_settings?.end_at_time,
                    return_pick_up_location: recurring_job_settings?.return_pick_up_location,
                    return_start_at_time: recurring_job_settings?.return_start_at_time,
                    return_drop_off_location: recurring_job_settings?.return_drop_off_location,
                    return_end_at_time: recurring_job_settings?.return_end_at_time,
                    start_at_date: recurring_job_settings?.start_at_date,
                    end_at_date: recurring_job_settings?.end_at_date,
                    pax: recurring_job_settings?.pax,
                    charge: recurring_job_settings?.charge,
                    vehicles_required: recurring_job_settings?.vehicles_required,
                    allocation: allocationFormResArr?.length ? allocationFormResArr : [defaultAllocationForm]
                }
                setCheckedDay(recurring_job_settings?.selected_days || [])
                setReturn_leg(recurring_job_settings?.return_leg)
                reset(data)
            } else {
                append([defaultAllocationForm])
            }
        } else {
            reset({})
            remove()
            setCheckedDay([])
            setPreventDriver([])
            setPreventVehicle([])
            setReturn_leg(false)
        }
    }, [openRecurringJobModal])

    const changeCheckbox = (value: any, checked: any) => {
        if (checked) {
            setCheckedDay(pre => [...pre, value])
        } else {
            const data = checkedDay?.filter((item) => item != value)
            setCheckedDay(data)
        }
    }

    const loadDriverOptions = (inputValue: any, callback: any) => {
        if (driverTimeoutId) {
          clearTimeout(driverTimeoutId);
        }
        driverTimeoutId = setTimeout(() => {
            const obj = { 
                getall: 1,
                fields: 'id,driver_name',
                driver_name : inputValue
            }
            DriverService.getDriverList(entity_id, obj)
            .then((res: any) => {
                callback(res?.data?.response?.map((item: any) => {
                    return { value: item?.id, label: item?.driver_name}
                }))
            })
          .catch(err => console.log(err?.response?.data?.message))
        }, 1000)
    }

    const loadVahicleOptions = (inputValue: any, callback: any) => {
        if (vahicleTimeoutId) {
            clearTimeout(vahicleTimeoutId);
        }
        vahicleTimeoutId = setTimeout(() => {
            const obj = { 
                getall: 1,
                fields: 'id,vehicle_name',
                vehicle_name : inputValue
            }
            VehicleService.getVehicleList(entity_id, obj)
            .then((res: any) => {
                callback(res?.data?.response?.map((item: any) => {
                    return { value: item?.id, label: item?.vehicle_name}
                }))
            })
            .catch(err => console.log(err?.response?.data?.message))
        }, 1000)
    }

    const handleSelectedDriverAndVehicle = (field: any) => {
        if (field === 'driver') {
            const selQuoteStatusArr = fields?.map((item: any) => item?.driver)
            setPreventDriver(selQuoteStatusArr)
        } else {
            const selQuoteStatusArr = fields?.map((item: any) => item?.vehicle)
            setPreventVehicle(selQuoteStatusArr)
        }
    }

    function manipulateAllocation(value: any) {
        if (value - fields?.length > 0) {
            const Arr: any = []
            for(let i=1; i <= value - fields?.length; i++) {
                Arr.push(defaultAllocationForm)
                // append(defaultAllocationForm)
            }
            setValue('allocation', [...fields, ...Arr])
        } else if (value - fields?.length === 0) {
        } else if (value === 0) {
            setValue('allocation', [])
        } else {
            const Arr = fields
            Arr.splice(value - fields?.length);
            setValue('allocation', Arr)
        }
    }

    useEffect(() => {
        if (openRecurringJobModal && return_leg) {
            const obj = getValues();
            obj.return_pick_up_location = obj?.drop_off_location;
            obj.return_drop_off_location = obj?.pick_up_location;
            obj.return_start_at_time = obj?.start_at_time
            obj.return_end_at_time = obj?.end_at_time;
            reset(obj);
        }
    }, [return_leg])

  return (
    <Modal isOpen={openRecurringJobModal} toggle={() => setOpenRecurringJobModal(false)} className={`modal-dialog-centered modal-lg`}>
        <Form onSubmit={handleSubmit(bookingId ? submitRecurring : recurringJobLeg)} onKeyDown={preventOnEnter}>
            <ModalBody className="p-10">
                <Row>
                    <Col md={5}>
                        <Label className='form-label' for='price'>Pickup</Label>
                            <Row>
                                <Col md={8}>
                                    <Controller name={`pick_up_location`} control={control} defaultValue=''
                                        render={({ field }) => (
                                            <AsyncCreatableSelect
                                                {...field}
                                                className='react-select'
                                                classNamePrefix='select'
                                                isClearable={false}
                                                isSearchable={true}
                                                styles={customSelectStyles(null)}
                                                loadOptions={loadLocationOptions}
                                                theme={selectThemeColors}
                                                onChange={(data: any) => {
                                                    field.onChange(data)
                                                }}
                                            />
                                        )}
                                    />
                                </Col>
                                <Col md={4}>
                                    <Controller name={`start_at_time`} control={control} defaultValue=''
                                        render= {({ field }) => (
                                            <Input type='time' className='cursor-pointer' {...field} 
                                                onChange={(e) => { 
                                                    field.onChange(e.target.value)
                                                    setValue(`end_at_time`, e.target.value)
                                                }}
                                            />
                                        )}
                                    />
                                </Col>
                            </Row>
                    </Col>
                    <Col md={5}>
                        <Label className='form-label' for='price'>Drop Off</Label>
                        <Row>
                            <Col md={8}>
                                <Controller name={`drop_off_location`} control={control} defaultValue=''
                                    render={({ field }) => (
                                        <AsyncCreatableSelect
                                            {...field}
                                            className='react-select'
                                            classNamePrefix='select'
                                            isClearable={false}
                                            isSearchable={true}
                                            styles={customSelectStyles(null)}
                                            loadOptions={loadLocationOptions}
                                            theme={selectThemeColors}
                                            onChange={(data: any) => {
                                                field.onChange(data)
                                            }}
                                        />
                                    )}
                                />
                            </Col>
                            <Col md={4}>
                                <Controller name={`end_at_time`} control={control} defaultValue=''
                                    render= {({ field }) => (
                                        <Input type='time' className='cursor-pointer' {...field} 
                                            onChange={(e) => { 
                                                field.onChange(e.target.value)
                                            }}
                                        />
                                    )}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col md={2}>
                        <Label className='form-label' for='price'>Return Leg</Label>
                        <Row className="ms-3 mt-3">
                            <Input type='checkbox' className='cursor-pointer'
                                checked={return_leg}
                                onChange={(e) => {
                                    setReturn_leg(e.target.checked)
                                }}
                            />
                        </Row>
                    </Col>
                </Row>

                {return_leg &&
                    <Row className="my-10">
                        <Label className='form-label' for='price'>Return</Label>
                        <Col md={5}>
                                <Row>
                                    <Col md={8}>
                                        <Controller name={`return_pick_up_location`} control={control} defaultValue=''
                                            render={({ field }) => (
                                                <AsyncCreatableSelect
                                                    {...field}
                                                    className='react-select'
                                                    classNamePrefix='select'
                                                    isClearable={false}
                                                    isSearchable={true}
                                                    styles={customSelectStyles(null)}
                                                    loadOptions={loadLocationOptions}
                                                    theme={selectThemeColors}
                                                    onChange={(data: any) => {
                                                        field.onChange(data)
                                                    }}
                                                />
                                            )}
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <Controller name={`return_start_at_time`} control={control} defaultValue=''
                                            render= {({ field }) => (
                                                <Input type='time' className='cursor-pointer' {...field} 
                                                    onChange={(e) => { 
                                                        field.onChange(e.target.value)
                                                        setValue(`return_end_at_time`, e.target.value)
                                                    }}
                                                />
                                            )}
                                        />
                                    </Col>
                                </Row>
                        </Col>
                        <Col md={5}>
                            {/* <Label className='form-label' for='price'>Drop Off</Label> */}
                            <Row>
                                <Col md={8}>
                                    <Controller name={`return_drop_off_location`} control={control} defaultValue=''
                                        render={({ field }) => (
                                            <AsyncCreatableSelect
                                                {...field}
                                                className='react-select'
                                                classNamePrefix='select'
                                                isClearable={false}
                                                isSearchable={true}
                                                styles={customSelectStyles(null)}
                                                loadOptions={loadLocationOptions}
                                                theme={selectThemeColors}
                                                onChange={(data: any) => {
                                                    field.onChange(data)
                                                }}
                                            />
                                        )}
                                    />
                                </Col>
                                <Col md={4}>
                                    <Controller name={`return_end_at_time`} control={control} defaultValue=''
                                        render= {({ field }) => (
                                            <Input type='time' className='cursor-pointer' {...field} 
                                                onChange={(e) => { 
                                                    field.onChange(e.target.value)
                                                }}
                                            />
                                        )}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                }

                <Row className="my-10">
                    <div style={{width: '14.2%'}}>
                        <span className="me-3">MON</span>
                        <Input type="checkbox" value='Monday' checked={checkedDay.includes('Monday')} onChange={(e) => changeCheckbox(e.target.value, e.target.checked)} />
                    </div>
                    <div style={{width: '14.2%'}}>
                        <span className="me-3">TUE</span>
                        <Input type="checkbox" value='Tuesday' checked={checkedDay.includes('Tuesday')} onChange={(e) => changeCheckbox(e.target.value, e.target.checked)} />
                    </div>
                    <div style={{width: '14.2%'}}>
                        <span className="me-3">WED</span>
                        <Input type="checkbox" value='Wednesday' checked={checkedDay.includes('Wednesday')} onChange={(e) => changeCheckbox(e.target.value, e.target.checked)} />
                    </div>
                    <div style={{width: '14.2%'}}>
                        <span className="me-3">THU</span>
                        <Input type="checkbox" value='Thursday' checked={checkedDay.includes('Thursday')} onChange={(e) => changeCheckbox(e.target.value, e.target.checked)} />
                    </div>
                    <div style={{width: '14.2%'}}>
                        <span className="me-3">FRI</span>
                        <Input type="checkbox" value='Friday' checked={checkedDay.includes('Friday')} onChange={(e) => changeCheckbox(e.target.value, e.target.checked)} />
                    </div>
                    <div style={{width: '14.2%'}}>
                        <span className="me-3">SAT</span>
                        <Input type="checkbox" value='Saturday' checked={checkedDay.includes('Saturday')} onChange={(e) => changeCheckbox(e.target.value, e.target.checked)} />
                    </div>
                    <div style={{width: '14.2%'}}>
                        <span className="me-3">SUN</span>
                        <Input type="checkbox" value='Sunday' checked={checkedDay.includes('Sunday')} onChange={(e) => changeCheckbox(e.target.value, e.target.checked)} />
                    </div>
                </Row>
                <Row>
                    <Col md='3'>
                        <Label className='form-label' for='price'>Start On</Label>
                        <Controller name={`start_at_date`} control={control} defaultValue=''
                            render= {({ field }) => (
                                <Flatpickr
                                    className='form-control cursor-pointer'
                                    placeholder='Select Start Date'
                                    {...field}
                                    options={{ 
                                        enableTime: false, 
                                        altInput: true, 
                                        dateFormat: 'Y-m-d H:i',
                                        disableMobile : true
                                    }}
                                    onChange={(value: any) => {
                                        field.onChange(moment(value[0]).format('YYYY-MM-DD'))
                                        setValue(`end_at_date`, moment(value[0]).format('YYYY-MM-DD'))
                                    }}
                                />
                            )}
                        />
                    </Col>
                    <Col md='3'>
                        <Label className='form-label' for='price'>End On</Label>
                        <Controller name={`end_at_date`} control={control} defaultValue=''
                            render= {({ field }) => (
                                <Flatpickr 
                                    className='form-control cursor-pointer' 
                                    placeholder='Select end Date'
                                    {...field}
                                    options={{ 
                                        enableTime: false, 
                                        altInput: true, 
                                        minDate: getValues('start_at_date'),
                                        dateFormat: 'Y-m-d H:i',
                                        disableMobile : true
                                    }}
                                    onChange={(value) => {
                                        field.onChange(moment(value[0]).format('YYYY-MM-DD'))
                                    }}
                                />
                            )}
                        />
                    </Col>
                    <Col md='2'>
                        <Label className='form-label' for='price'>PAX</Label>
                        <Controller name={`pax`} control={control} defaultValue=''
                            render= {({ field }) => (
                                <Input placeholder='Enter PAX' type='number' min={0}
                                    onKeyDown={(e) => preventDefualt(e)}
                                    {...field}
                                    onChange={(e) => {
                                      field.onChange(e.target.value)
                                    }}
                                />
                            )}
                        />
                    </Col>
                    <Col md='2'>
                        <Label className='form-label' for='price'>Charge</Label>
                        <Controller name={`charge`} control={control} defaultValue=''
                            render= {({ field }) => (
                                <Input placeholder='Enter charge' type='number' min={0}
                                    onKeyDown={(e) => preventDefualt(e)}
                                    {...field}
                                    disabled={priceType?.value === 0}
                                    onChange={(e) => {
                                      field.onChange(e.target.value)
                                    }}
                                />
                            )}
                        />
                    </Col>
                    <Col md='2'>
                        <Label className='form-label' for='price'>Vehicles Req</Label>
                        <Controller name={`vehicles_required`} control={control} defaultValue={1}
                            render= {({ field }) => (
                                <Input placeholder='Enter Vehicles Required' type='number' min={1}
                                    onKeyDown={(e) => preventDefualt(e)}
                                    {...field} 
                                    onFocus={(e) => (e.target as any).select()}
                                    onChange={(e) => { 
                                        if (e.target.value === '') {
                                            e.preventDefault();
                                            return false;
                                        }
                                        field.onChange(e.target.value)
                                        manipulateAllocation(Number(e.target.value))
                                    }}
                                />
                            )}
                        />
                    </Col>
                </Row>
                <Row className='my-10'>
                    <Row className='my-4'>
                        <Col md={4} className="qs-head">
                            <Label className='form-label' for='price'>Driver</Label>
                        </Col>
                        <Col md={4} className="qs-head">
                            <Label className='form-label' for='price'>Vehicle</Label>
                        </Col>
                    </Row>
                    <div style={{ minHeight: '150px', maxHeight: '300px', overflowY: 'scroll', overflowX: 'hidden' }}>
                        { fields?.length > 0 ? fields?.map((item, index) => (
                            <Row key={item?.id} className="mb-3">
                                <Col md={4}>
                                    <Controller name={`allocation.${index}.driver`} control={control} defaultValue={defaultDriver}
                                        render={({ field }) => (
                                            <AsyncSelect
                                                {...field} 
                                                isClearable={true}
                                                className='react-select w-100'
                                                classNamePrefix='select'
                                                placeholder='Search Driver'
                                                isRtl={false}
                                                name='callback-react-select'
                                                theme={selectThemeColors}
                                                loadOptions={loadDriverOptions}
                                                cacheOptions
                                                onChange={(data: any) => {
                                                    field.onChange(data)
                                                    handleSelectedDriverAndVehicle('driver')
                                                }}
                                                isOptionDisabled = {(option) => {
                                                    return preventDriver?.some((elem) => elem?.value === option?.value)
                                                }}
                                                styles={styles}
                                            />
                                        )}
                                    />
                                </Col> 
                                <Col md={4}>
                                    <Controller name={`allocation.${index}.vehicle`} control={control} defaultValue={defaultVehicle}
                                        render={({ field }) => (
                                            <AsyncSelect
                                                {...field} 
                                                isClearable={true}
                                                className='react-select w-100'
                                                classNamePrefix='select'
                                                placeholder='Search Vehicle'
                                                isRtl={false}
                                                name='callback-react-select'
                                                theme={selectThemeColors}
                                                loadOptions={loadVahicleOptions}
                                                cacheOptions
                                                onChange={(data: any) => {
                                                    field.onChange(data)
                                                    handleSelectedDriverAndVehicle('vehicle')
                                                }}
                                                isOptionDisabled = {(option) => {
                                                    return preventVehicle?.some((elem) => elem?.value === option?.value)
                                                }}
                                                styles={styles}
                                            />
                                        )}
                                    />
                                </Col>
                            </Row>
                        )) : (
                            <Row>
                                <Col xs={12} lg={12}>
                                    no Record found
                                </Col>
                            </Row>
                        )}
                    </div>
                </Row>
            </ModalBody>
            <ModalFooter>
                <div>
                    <Button type="button" color="secondary" className="me-3" onClick={() => setOpenRecurringJobModal(false)}>
                        Close
                    </Button>
                    <Button type='submit' color='primary' disabled={loader}>
                        { loader ? <Spinner size='sm'/> : <span className='align-middle d-sm-inline-block d-none'>Create</span>}
                    </Button>
                </div>
            </ModalFooter>
        </Form>
    </Modal>
  )
}

export default AddRecurringJob