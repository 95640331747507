import React, { useEffect, useState } from 'react'
import { Button, CardText, Col, Form, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from 'reactstrap'
import AsyncSelect from 'react-select/async'
import { DriverService } from '../../modules/Services/Drivers'
import { VehicleService } from '../../modules/Services/Vehicles'
import { JobLegsService } from '../../modules/Services/JobLegs'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import moment from 'moment'

function AllocateResource({ openAllocateResource, 
                            setOpenAllocateResource, 
                            changeCheckbox, 
                            selectAll, 
                            jobLegsData, 
                            setRenderElementService, 
                            currentCustomer, 
                            statusIds, 
                            currentDriver,
                            jobDate, 
                            filter_tag,
                            searchTerm
}) {

    const { control, handleSubmit, getValues, setValue } = useForm()
    const entity_id = localStorage.getItem('entity_id')
    const [loader, setLoader] = useState(null)
    const [preventDriver, setPreventDriver] = useState([])
    const [preventVehicle, setPreventVehicle] = useState([])
    const [errorMessage, setErrorMessage] = useState('')

    let driverTimeoutId = null;
    let vahicleTimeoutId = null;

    const defaultDriver = { label: 'Select Driver', value: null }
    const defaultVehicle = { label: 'Select Vehicle', value: null }

    const { fields, append } = useFieldArray({
        control,
        name: "allocation"
    });

    const styles = {
        option: (baseStyles: any, state: any) => ({
            ...baseStyles,
            backgroundColor: state.isDisabled ? '#e6e6e6' : '',
            color: state.isDisabled ? '#b9b9c3' : '',
            cursor: state.isDisabled ? 'not-allowed' : ''
        })
    }

    useEffect(() => {
        if (openAllocateResource) {
            const data = selectAll?.all ? jobLegsData : jobLegsData?.filter((item: any) => {
                if (changeCheckbox.includes(item?.id)) { return item }
            })

            const isSame = data?.every((item: any) => item.vehicles_required === data[0].vehicles_required);
            if (!isSame) return setErrorMessage('Sorry, you can only do this for Jobs with the same number of Vehicles required.')

            const array = [];
            for (let i = 0; i < data[0]?.vehicles_required; i++) {
                array.push({ driver: defaultDriver, vehicle: defaultVehicle })
            }
            append(array)
        } else {
            setValue('allocation', [])
            setErrorMessage('')
            setLoader(null)
        }
    }, [openAllocateResource])


    const loadDriverOptions = (inputValue: any, callback: any) => {
        if (driverTimeoutId) {
            clearTimeout(driverTimeoutId);
        }
        driverTimeoutId = setTimeout(() => {
            const obj = {
                getall: 1,
                fields: 'id,driver_name',
                driver_name: inputValue
            }
            DriverService.getDriverList(entity_id, obj)
                .then((res: any) => {
                    callback(res?.data?.response?.map((item: any) => {
                        return { value: item?.id, label: item?.driver_name }
                    }))
                })
                .catch(err => console.log(err?.response?.data?.message))
        }, 1000)
    }

    const loadVehicleOptions = (inputValue: any, callback: any) => {
        if (vahicleTimeoutId) {
            clearTimeout(vahicleTimeoutId);
        }
        vahicleTimeoutId = setTimeout(() => {
            const obj = {
                getall: 1,
                fields: 'id,vehicle_name',
                vehicle_name: inputValue
            }
            VehicleService.getVehicleList(entity_id, obj)
                .then((res: any) => {
                    callback(res?.data?.response?.map((item: any) => {
                        return { value: item?.id, label: item?.vehicle_name }
                    }))
                })
                .catch(err => console.log(err?.response?.data?.message))
        }, 1000)
    }

    const allocateResource = (data: any) => {
        setLoader('allocate')

        let drivers = [];
        let vehicles = [];
        data?.allocation?.map((item: any) => {
            drivers.push(item?.driver?.value || null)
            vehicles.push(item?.vehicle?.value || null)
        })
        let payload: any = { 
            job_ids: selectAll?.all ? 'all' : changeCheckbox, 
            driver_ids: drivers, 
            vehicle_ids: vehicles
        }

        if (selectAll?.all) {
            payload = {
                ...payload,
                customer: currentCustomer?.value ? currentCustomer?.value : null,
                filter_status: statusIds?.length ? statusIds : null,
                driver: currentDriver?.value ? currentDriver?.value : null, 
                start_date : jobDate?.length ? (jobDate[0] ? moment(jobDate[0]?.toISOString()).format("YYYY-MM-DD") : null) : null,
                end_date : jobDate?.length ? (jobDate[1] ? moment(jobDate[1]?.toISOString()).format("YYYY-MM-DD") : null ): null,
                filter_tag: filter_tag?.value ? filter_tag?.value : null,
                search: searchTerm ? searchTerm : null
            }
        }

        JobLegsService.allocateResource(entity_id, payload)
            .then((res: any) => {
                toast.success(res?.data?.message)
                setRenderElementService(1)
            })
            .catch(err => console.log(err?.response?.data?.message))
            .finally(() => {
                setLoader(null)
                setOpenAllocateResource(false)
            })
    }

    const handleSelectedDriverAndVehicle = (field: any) => {
        const allocation = getValues('allocation');
        if (field === 'driver') {
            const selQuoteStatusArr = allocation?.map((item: any) => item?.driver)
            setPreventDriver(selQuoteStatusArr)
        } else {
            const selQuoteStatusArr = allocation?.map((item: any) => item?.vehicle)
            setPreventVehicle(selQuoteStatusArr)
        }
    }

    return (
        <Modal isOpen={openAllocateResource} toggle={() => setOpenAllocateResource(false)} className='modal-dialog-centered model-lg' >
            <ModalHeader className={`bg-white d-flex justify-content-center`} style={{ borderBottom: 'none' }}>
                <CardText style={{ fontSize: '17px' }}>Allocate Resources to Jobs</CardText>
            </ModalHeader>
            <Form onSubmit={handleSubmit(allocateResource)}>
                <ModalBody>
                    {fields?.length ?
                        <Row>
                            <Col md='6'>
                                <Label className='form-label' for='end'>Drivers</Label>
                            </Col>
                            <Col md='6'>
                                <Label className='form-label' for='end'>Vehicles</Label>
                            </Col>
                        </Row>
                        :
                        <Row>
                            <Col xs={12} lg={12}>
                                {errorMessage}
                            </Col>
                        </Row>
                    }
                    <div style={{ height: '300px', overflowY: 'scroll', overflowX: 'hidden' }}>
                        {fields?.map((item, index) => (
                            <Row key={item?.id} className="mb-3">
                                <Col md={6}>
                                    <Controller name={`allocation.${index}.driver`} control={control} defaultValue={defaultDriver}
                                        render={({ field }) => (
                                            <AsyncSelect
                                                {...field}
                                                isClearable={true}
                                                className='react-select w-100'
                                                classNamePrefix='select'
                                                placeholder='Search Driver'
                                                isRtl={false}
                                                name='callback-react-select'
                                                loadOptions={loadDriverOptions}
                                                cacheOptions
                                                onChange={(data: any) => {
                                                    field.onChange(data)
                                                    handleSelectedDriverAndVehicle('driver')
                                                }}
                                                isOptionDisabled={(option) => {
                                                    console.log('preventDriver', preventDriver, option)
                                                    return preventDriver?.some((elem) => elem?.value === option?.value)
                                                }}
                                                styles={styles}
                                            />
                                        )}
                                    />
                                </Col>
                                <Col md={6}>
                                    <Controller name={`allocation.${index}.vehicle`} control={control} defaultValue={defaultVehicle}
                                        render={({ field }) => (
                                            <AsyncSelect
                                                {...field}
                                                isClearable={true}
                                                className='react-select w-100'
                                                classNamePrefix='select'
                                                placeholder='Search Vehicle'
                                                isRtl={false}
                                                name='callback-react-select'
                                                loadOptions={loadVehicleOptions}
                                                cacheOptions
                                                onChange={(data: any) => {
                                                    field.onChange(data)
                                                    handleSelectedDriverAndVehicle('vehicle')
                                                }}
                                                isOptionDisabled={(option) => {
                                                    return preventVehicle?.some((elem) => elem?.value === option?.value)
                                                }}
                                                styles={styles}
                                            />
                                        )}
                                    />
                                </Col>
                            </Row>
                        ))}
                    </div>
                </ModalBody>
                <ModalFooter style={{ borderTop: 'none' }}>
                    <div className="d-flex justify-content-end w-100">
                        <Button color="secondary" onClick={() => setOpenAllocateResource(false)}>Cancel</Button>
                        {!errorMessage &&
                            <Button color="primary" type='submit' className="ms-2" disabled={loader}>
                                {loader ? <Spinner size='sm' /> : 'Update'}
                            </Button>
                        }
                    </div>
                </ModalFooter>
            </Form>
        </Modal>
    )
}

export default AllocateResource