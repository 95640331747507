import { Card, CardBody, CardHeader, CardTitle, Col, Form, Input, Label, Row, Spinner } from "reactstrap"
import { Fragment } from "react/jsx-runtime"
import { Button } from "react-bootstrap"
import UILoader from "../../modules/commonModel/Ui-loader"
import ComponentSpinner from "../../modules/apps/user-management/users-list/components/loading/Loading-spinner"
import { Controller, useForm } from "react-hook-form"
import { useEffect, useState } from "react"
import toast from "react-hot-toast"
import { userInfo } from "../../modules/auth/core/_requests"
import { useAuth } from "../../modules/auth"
import ResetPasswordModal from "./ResetPasswordModal"
import { UsersService } from "../../modules/Services/Users"

function ProfilePage() {
    const entity_id = localStorage.getItem('entity_id')
    const { currentUser, setCurrentUser, logout } = useAuth()
    const { control, handleSubmit, reset, formState: { errors } } = useForm()
    const [openResetPassword, setOpenResetPassword] = useState(false)
    const [loader, setLoader] = useState('')
    const formData = new FormData()

    function editData() {
        setLoader('edit')
        UsersService.editUser(entity_id, currentUser?.id)
            .then((res: any) => {
                const obj = {
                    first_name: res.data?.response?.first_name,
                    last_name: res.data?.response?.last_name,
                    email: res.data?.response?.email
                }
                reset(obj)
            })
            .catch((err: any) => {
                toast.error(err?.response?.data?.message)
            })
            .finally(() => setLoader(''))
    }

    useEffect(() => {
        if (currentUser) editData()
    }, [currentUser])

    function fetchFormData(data: any) {
        setLoader('update')
        formData.append('first_name', data?.first_name)
        formData.append('last_name', data?.last_name)
        formData.append('email', data?.email)

        UsersService.updateUser(entity_id, formData, currentUser?.id)
            .then((res: any) => {
                toast.success(res?.data?.message)

                userInfo(localStorage.getItem('entity_id'))
                    .then((res: any) => {
                        setCurrentUser(res?.data)
                        localStorage.setItem('auth_user', JSON.stringify(res?.data))
                        localStorage.setItem('entity_id', '1')
                    })
                    .catch((err: any) => {
                        toast.error(err?.response?.data?.message)
                        logout()
                    })
            })
            .catch((err: any) => {
                toast.error(err?.response?.data?.message)
            })
            .finally(() => setLoader(''))
    }

    return (
        <Fragment>
            <ResetPasswordModal
                openResetPassword={openResetPassword}
                setOpenResetPassword={setOpenResetPassword}
            />
            <Card>
                <UILoader blocking={loader === 'edit'} loader={<ComponentSpinner />} overlayColor='rgba(245, 245, 240, .1)' className={loader === 'edit' ? 'loader-blur' : ''}>
                    <CardHeader className="d-flex align-items-center">
                        <CardTitle className='dropdown-header'>Profile</CardTitle>
                    </CardHeader>
                    <CardBody>
                        <Form onSubmit={handleSubmit(fetchFormData)}>
                            <Row>
                                <Col md='4' className='mb-6'>
                                    <Label className='form-label' for='first_name'>First Name</Label>
                                    <Controller name='first_name' control={control} rules={{ required: true }}
                                        render={({ field }) => <Input placeholder='Enter First Name' type="text" invalid={errors.first_name && true} {...field} />}
                                    />
                                    {errors.first_name && (<small className="text-danger ml-2 ">First Name is a required field</small>)}
                                </Col>
                                <Col md='4' className='mb-6'>
                                    <Label className='form-label' for='last_name'>Second Name</Label>
                                    <Controller name='last_name' control={control} rules={{ required: true }}
                                        render={({ field }) => <Input placeholder='Enter Second Name' type="text" invalid={errors.last_name && true} {...field} />}
                                    />
                                    {errors.last_name && (<small className="text-danger ml-2 ">Second Name is a required field</small>)}
                                </Col>
                                <Col md='4' className='mb-6'>
                                    <Label className='form-label' for='Mobile'>Email</Label>
                                    <Controller name='email' control={control} rules={{ required: true }}
                                        render={({ field }) => <Input placeholder='Enter Email' type="text" invalid={errors.email && true} {...field} />}
                                    />
                                    {errors.email && (<small className="text-danger ml-2 ">Email is a required field</small>)}
                                </Col>
                                <Col md='4' className='mb-6'>
                                    <Button
                                        type="button"
                                        className="btn btn-sm btn-success"
                                        onClick={() => setOpenResetPassword(true)}
                                    >
                                        Reset Password
                                    </Button>
                                </Col>
                            </Row>
                            <div className='d-flex justify-content-end mt-10'>
                                <div>
                                    <Button type='submit' color='primary' disabled={loader === 'update'} className='btn-next'>
                                        {loader === 'update' ? <Spinner size='sm' /> : <span className='align-middle d-sm-inline-block d-none'>Save</span>}
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    </CardBody>
                </UILoader>
            </Card>
        </Fragment>
    )
}

export default ProfilePage