import axios from "axios";
import { AuthModel, UserModel } from "./_models";
import {useAuth} from '../core/Auth'

const API_URL = import.meta.env.VITE_APP_API_URL;

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`;
export const LOGIN_URL = `${API_URL}login`;
export const USER_INFO_URL = `${API_URL}login-info`;
export const LOGOUT_URL = `${API_URL}/logout`;
export const SETPASSWORD_URL = `${API_URL}activate`;
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`;

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    email,
    password,
  });
}

export function userInfo(entity_id: any) {
  return new Promise((resolve, reject) => {
      axios.get(`${USER_INFO_URL}`, { headers: { 'Entity-Id': entity_id } })
      .then((response) => resolve(response))
      .catch((error) => reject(error))
  })
}

// const {saveAuth, setCurrentUser} = useAuth()
// export async function Logout() {
//   const data = await axios.post<AuthModel>(LOGOUT_URL);
//   if (data) {
//     saveAuth(undefined)
//     setCurrentUser(undefined)
//   }
// }

// Server should return AuthModel
export function setPassword(
  email: string,
  password: string,
  token: string,
  entity_id: any
) {
  return axios.post(SETPASSWORD_URL, {
    email,
    password,
    token,
    entity_id
  });
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
    email,
  });
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  });
}
