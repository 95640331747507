import { Controller, useForm } from 'react-hook-form'
import { Button, CardText, Col, Form, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from 'reactstrap'
import { customSelectStyles, selectThemeColors } from '../../modules/commonModel/Utils'
import { useEffect, useState } from 'react'
import ReactSelect from 'react-select'
import toast from 'react-hot-toast'
import { JobLegsService } from '../../modules/Services/JobLegs'
import moment from 'moment'

function AllocateTags({ 
                        openAllocateTags,
                        setOpenAllocateTags,
                        tagData,
                        changeCheckbox,
                        setRenderElementService,
                        selectAll,
                        currentCustomer,
                        statusIds,
                        currentDriver,
                        jobDate,
                        filter_tag,
                        searchTerm
}) {

    const entity_id = localStorage.getItem('entity_id')
    const { control, handleSubmit, formState: { errors } } = useForm()
    const [currentTag, setCurrentTag] = useState([])
    const [loader, setLoader] = useState('')

    const submit = () => {
        setLoader('update')
        const jobIds = currentTag?.map((item: any) => item?.value);
        let payload: any = {
            job_ids: selectAll?.all ? 'all' : changeCheckbox,
            tags: jobIds
        }

        if (selectAll?.all) {
            payload = {
                ...payload,
                customer: currentCustomer?.value ? currentCustomer?.value : null,
                filter_status: statusIds?.length ? statusIds : null,
                driver: currentDriver?.value ? currentDriver?.value : null, 
                start_date : jobDate?.length ? (jobDate[0] ? moment(jobDate[0]?.toISOString()).format("YYYY-MM-DD") : null) : null,
                end_date : jobDate?.length ? (jobDate[1] ? moment(jobDate[1]?.toISOString()).format("YYYY-MM-DD") : null ): null,
                filter_tag: filter_tag?.value ? filter_tag?.value : null,
                search: searchTerm ? searchTerm : null
            }
        }

        JobLegsService.updateBulkTags(entity_id, payload)
            .then((res: any) => {
                toast.success(res?.data?.message)
                setOpenAllocateTags(false)
                setRenderElementService(1)
            })
            .catch((err: any) => {
                toast.error(err?.response?.data?.message)
            })
            .finally(() => setLoader(''))
    }

    useEffect(() => {
        if (!openAllocateTags) setCurrentTag([])
    }, [openAllocateTags])

    return (
        <Modal isOpen={openAllocateTags} toggle={() => setOpenAllocateTags(false)} className='modal-dialog-centered model-lg' >
            <ModalHeader className={`bg-white d-flex justify-content-center`} style={{ borderBottom: 'none' }}>
                <CardText style={{ fontSize: '17px' }}>Allocate Tags to Jobs</CardText>
            </ModalHeader>
            <Form onSubmit={handleSubmit(submit)}>
                <ModalBody>
                    <Row className="mb-3">
                        <Label className='form-label'>Tags</Label>
                        <Controller name={`tags`} control={control} rules={{required: true}} defaultValue={''}
                            render={({ field: {onChange} }) => (
                                <ReactSelect
                                    isMulti
                                    isClearable={false}
                                    isSearchable={false}
                                    className='react-select w-100 text-start'
                                    classNamePrefix='select'
                                    theme={selectThemeColors}
                                    styles={customSelectStyles(errors.tags)}
                                    options={tagData}
                                    value={currentTag}
                                    onChange={(data: any) => {
                                        onChange(data)
                                        setCurrentTag(data)
                                    }}
                                />
                            )}
                        />
                        {errors.tags && (<small className="text-danger ml-2 ">Tags is a required field</small>)}
                    </Row>
                </ModalBody>
                <ModalFooter style={{ borderTop: 'none' }}>
                    <div className="d-flex justify-content-end w-100">
                        <Button color="secondary" size='sm' onClick={() => setOpenAllocateTags(false)}>Cancel</Button>
                        <Button color="primary" type='submit' className="ms-2" size='sm' disabled={loader === 'update'}>
                            {loader ? <Spinner size='sm' /> : 'Update'}
                        </Button>
                    </div>
                </ModalFooter>
            </Form>
        </Modal>
    )
}

export default AllocateTags