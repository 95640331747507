import React, { Fragment } from 'react'
import { Col, Row } from 'reactstrap'
import BookingStatus from './BookingStatus';
import JobStatus from './JobStatus';
import StripeConfiguration from './StripeConfiguration';
import DomainConfiguration from './DomainConfiguration';
import EntityProfile from './EntityProfile';

function SettingPage() {

  return (
    <Fragment>
        <Row>
            <Col md='12' className='mb-5'>
                <EntityProfile />
            </Col>
            <Col md={4} className='mb-5'>
                <BookingStatus />
            </Col>
            <Col md={4}>
                <JobStatus />
            </Col>
            <Col md={4}>
                <StripeConfiguration />
            </Col>
            <Col md={12} className='mb-10'>
                <DomainConfiguration />
            </Col>
        </Row>
    </Fragment>
  )
}

export default SettingPage