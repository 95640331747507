import React,{Fragment, useContext, useEffect, useState} from 'react'
import {KTCard, KTCardBody, KTIcon,} from '../../../_metronic/helpers'
import { useNavigate } from 'react-router'

import toast from 'react-hot-toast'
import ComponentSpinner from '../../modules/apps/user-management/users-list/components/loading/Loading-spinner'
import moment from 'moment'
import { Button,Col, Container, Input, Label, Row, Table } from 'reactstrap'
import { JobService } from '../../modules/Services/Job'
import ReactSelect from 'react-select'
import { customSelectStyles, selectThemeColors } from '../../modules/commonModel/Utils'
import { CustomerService } from '../../modules/Services/Customers'
import AsyncSelect from 'react-select/async'
import { FilterDetails } from '../../App'
import UILoader from '../../modules/commonModel/Ui-loader'
import { TagsService } from '../../modules/Services/Tags'
import { Icon } from '@iconify/react'

import { Dayjs } from 'dayjs';
import { DateRange } from '@mui/x-date-pickers-pro/models';
import MultiDatePickr from '../../modules/commonModel/MultiDatePickr'

const JobPage = () => {

  const tableHearder = [
    {label: 'Booking ID', asc_desc: undefined, value: 'booking_number', style: 'pointer'},
    {label: 'Booking Name', asc_desc: undefined, value: 'job_name', style: 'pointer'},
    {label: 'Client', asc_desc: undefined, value: 'customer_name', style: 'pointer'},
    {label: 'Pickup', asc_desc: undefined, value: null, style: ''},
    {label: 'Job Dates', asc_desc: undefined, value: 'start_at_date', style: 'pointer'},
    {label: 'Jobs', asc_desc: undefined, value: null, style: ''},
    {label: 'Charge', asc_desc: undefined, value: null, style: ''}
  ]

  const [filterValue, setFilterValue] = useContext<any>(FilterDetails)
  const [pending, setPending] = useState(false)
  const navigate = useNavigate()
  const [bookingData, setBookingData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const entity_id = localStorage.getItem('entity_id')
  const [currentCustomer, setCurrentCustomer] = useState((Object.keys(filterValue?.bookings?.customer).length > 0) ? filterValue?.bookings?.customer : { value: '', label: 'Select Client' })
  const [statusData, setStatusData] = useState([])
  const [currentStatus, setCurrentStatus] = useState((Object.keys(filterValue?.bookings?.status).length > 0) ? filterValue?.bookings?.status : { value: '', label: 'Select Status' })
  const [searchTerm, setSearchTerm] = useState(Object.keys(filterValue?.bookings?.booking_search)?.length ? filterValue?.bookings?.booking_search : '')
  const [renderElementService, setRenderElementService] = useState(1)
  const [nextPage, setNextPage] = useState(null)
  const [tagData, setTagData] = useState([{ value: '', label: 'Select Tag'}])
  const [currentTag, setCurrentTag] = useState((Object.keys(filterValue?.bookings?.tag).length > 0) ? filterValue?.bookings?.tag : { value: '', label: 'Select Tag' })
  let customerTimeoutId: ReturnType<typeof setTimeout> | null = null;
  const [tableHearderField, setTableHearderField] = useState(tableHearder)
  const [sortOrder, setSortOrder] = useState((Object.keys(filterValue?.bookings?.order_by).length > 0) ? filterValue?.bookings?.order_by : 'desc');
  const [sort, setSort] = useState((Object.keys(filterValue?.bookings?.sort_by).length > 0) ? filterValue?.bookings?.sort_by : 'start_at_date');

  const [selectedDateRange, setSelectedDateRange] = React.useState<DateRange<Dayjs>>((Object.keys(filterValue?.bookings?.date).length > 0) ? filterValue?.bookings?.date : [null, null]);
  const [bookingDate, setBookingDate] = useState((Object.keys(filterValue?.bookings?.date).length > 0) ? filterValue?.bookings?.date : [null, null]);

  const getBooking = () => {
    setPending(true)
    JobService.getJobList(entity_id, {
      per_page: 17,
      page: currentPage,
      customer: currentCustomer?.value ? currentCustomer?.value : null,
      status: currentStatus?.value ? currentStatus?.value : null,
      tag: currentTag?.value ? currentTag?.value : null, 
      search: searchTerm ? searchTerm : null,
      start_date : bookingDate?.length ? (bookingDate[0] ? moment(bookingDate[0]?.toISOString()).format("YYYY-MM-DD") : null) : null,
      end_date : bookingDate?.length ? (bookingDate[1] ? moment(bookingDate[1]?.toISOString()).format("YYYY-MM-DD") : null) : null,
      sort_by: sortOrder ? sortOrder : null,
      order_by: sort ? sort : null,
    })
    .then((res: any) => { 
      if (currentPage > 1) setBookingData(pre => [...pre, ...res?.data?.response?.data]) 
      else setBookingData(res?.data?.response?.data)
      setNextPage(res?.data?.response?.next_page_url)
    })
    .catch((err: any) => {
      toast.error(err?.response?.data?.message)
    })
    .finally(() => setPending(false))
  }

  const loadOptions = (inputValue: any, callback: any) => {
    if (customerTimeoutId) {
      clearTimeout(customerTimeoutId);
    }
    customerTimeoutId = setTimeout(() => {
      const obj = { 
        getall: 1,
        fields: 'id,customer_name',
        customer_name : inputValue
      }
      CustomerService.getCustomerList(entity_id, obj)
      .then((res: any) => {
        callback(res?.data?.response?.map((item: any) => {
          return { value: item?.id, label: item?.customer_name}
        }))
      })
      .catch(err => console.log(err?.response?.data?.message))
    }, 1000)
  }

  function getStatusList() {
    JobService.getJobStatusList(entity_id)
    .then((res: any) => {
      const customerList = res?.data?.response?.map((item: any) => {
        return { value: item?.id, label: item?.label }
      })
      const dafault = { value: '', label: 'Select Status'}
      setStatusData([dafault, ...customerList])
    })
    .catch((err: any) => {
      toast.error(err?.response?.data?.message)
    })
  }

  function getTagList() {
    const data = {
      getall: 1,
      fields: 'id,name'
    }
    TagsService.getTagsList(entity_id, data)
    .then((res: any) => {
      const tagsList = res?.data?.response?.map((item: any) => {
        return { value: item?.id, label: item?.name }
      })
      setTagData(pre => [...pre, ...tagsList])
    })
    .catch((err: any) => {
      toast.error(err?.response?.data?.message)
    })
  }

  useEffect(() => {
    getStatusList()
    getTagList()
  }, [])

  const handleSearch = (val: any) => {
    setSearchTerm(val)
    setFilterValue({...filterValue, bookings: {...filterValue?.bookings, booking_search: val }})
  }

  useEffect(() => {
    if (searchTerm) {
      const delayDebounceFn = setTimeout(() => {
        scrollTop()
      }, 1000);
      return () => clearTimeout(delayDebounceFn)
    } else {
      scrollTop()
    }
  }, [searchTerm])

  useEffect(() => {
    if (renderElementService) {
      setRenderElementService(0)
      getBooking()
    }
  }, [renderElementService])

  function handleScroll(event: any) {
    const scrollHeight = event.target.scrollTop + event.target.clientHeight
    const elementHeight = event.target.scrollHeight
    if (event.target.scrollTop > 0) {
      if (((scrollHeight + 100) >= elementHeight) && nextPage && !pending && !renderElementService) {
        setCurrentPage((p: any) => p + 1)
        setRenderElementService((prev) => prev + 1)
      }
    }
  }

  function scrollTop() {
    const table = document.getElementById('booking_table')
    table.scrollTo(0, 0)
    setCurrentPage(1)
    setRenderElementService(1)
  }

  const handleClearDate = () => {    
    scrollTop();
    setBookingDate([null, null]);
    setSelectedDateRange([null, null]);
    setFilterValue({...filterValue, bookings: {...filterValue?.bookings, date: [null, null]}})
  };

  const handleDateChange = () => {
    setBookingDate(selectedDateRange)
    setFilterValue({ ...filterValue, bookings: { ...filterValue?.bookings, date: selectedDateRange } })
    scrollTop()
  }

  function onChangeFilter(event: any, fieldText: any) {
    setSortOrder('asc');
    const sort = tableHearderField?.map((item: any) => {
      if(item?.value == fieldText) {
        if(event) return {...item, asc_desc: false};
        return {...item, asc_desc: true};
      } else {
        return {...item, asc_desc: undefined}
      }
    })
    setTableHearderField(sort);
    if (event) setSortOrder('desc');
    setSort(fieldText);
    scrollTop();
    setFilterValue({...filterValue, bookings: {...filterValue?.bookings, sort_by: fieldText, order_by: event ? 'desc' : 'asc'}})
  }

  return (
    <>
      <KTCard className='mb-3'>
        <KTCardBody className='p-5'>
        <Container fluid>
          <Row>
            <Col md= '3' xs={12} className='d-flex align-items-center'>
              {/* <Label className='form-label me-4 mb-0'>Booking</Label> */}
                <Input type='search' placeholder='Search Bookings..' value={searchTerm} onChange={(e) => handleSearch(e?.target?.value)} />
            </Col>
            <Col md= '2' xs={12} className='d-flex align-items-center'>
              {/* <Label className='form-label me-4 mb-0'>Customer</Label> */}
                <AsyncSelect
                  isClearable={true}
                  className='react-select w-100'
                  classNamePrefix='select'
                  placeholder='Select Client..'
                  isRtl={false}
                  name='callback-react-select'
                  theme={selectThemeColors}
                  styles={customSelectStyles(null)}
                  loadOptions={loadOptions}
                  value={currentCustomer}
                  cacheOptions
                  onChange={(data: any) => {
                    scrollTop()
                    setCurrentCustomer(data)
                    setFilterValue({...filterValue, bookings: {...filterValue?.bookings, customer: data || { value: '', label: 'Select Client' }}})
                  }}
                />
            </Col>
            <Col md= '2' xs={12} className='d-flex align-items-center'>
              {/* <Label className='form-label me-4 mb-0'>Status</Label> */}
              <ReactSelect
                isClearable={false}
                isSearchable={false}
                className='react-select w-100 text-start'
                classNamePrefix='select'
                theme={selectThemeColors}
                styles={customSelectStyles(null)}
                options={statusData}
                value={currentStatus}
                onChange={(data: any) => {
                  scrollTop()
                  setCurrentStatus(data)
                  setFilterValue({...filterValue, bookings: {...filterValue?.bookings, status: data}})
                }}
              />
            </Col>
            <Col md= '2' xs={12} className='d-flex align-items-center'>
              {/* <Label className='form-label me-4 mb-0'>Tag</Label> */}
              <ReactSelect
                isClearable={false}
                isSearchable={false}
                className='react-select w-100 text-start'
                classNamePrefix='select'
                theme={selectThemeColors}
                styles={customSelectStyles(null)}
                options={tagData}
                value={currentTag}
                onChange={(data: any) => {
                  scrollTop()
                  setCurrentTag(data)
                  setFilterValue({...filterValue, bookings: {...filterValue?.bookings, tag: data}})
                }}
              />
            </Col>
            <Col md= '3' xs={12} className='d-flex align-items-center'>
              <MultiDatePickr 
                handleClearDate={handleClearDate}
                handleDateChange={handleDateChange}
                date={bookingDate}
                selectedDateRange={selectedDateRange}
                setSelectedDateRange={setSelectedDateRange}
              />
            </Col>
          </Row>
          </Container>
        </KTCardBody>
      </KTCard>
      <KTCard>
        <KTCardBody className='p-0'>
          <div className='invoice-list-table-header w-100 pe-10 my-5'>
            <Row xl="2" lg="2" md="2" sm="1" xs="1">
              <Col className='d-flex align-items-center p-0'>
              </Col>
              <Col className='d-flex align-items-center justify-content-end p-0'>
                <div className="d-flex flex-row align-items-center">
                  <a href='#' className='btn btn-sm btn-primary' onClick={() => navigate('/booking/add')}>
                    <KTIcon iconName='plus' className='fs-3' />
                    New Booking
                  </a>
                </div>
              </Col>
            </Row>
          </div>
          <UILoader blocking={ pending } loader={<ComponentSpinner />} overlayColor='rgba(245, 245, 240, .1)' className={pending ? 'loader-blur' : ''}>
            <div onScroll={(e) => handleScroll(e)} id='booking_table' className='jobs-table-style'>
              <Table className='jobs-table'>
                <thead className='sticky-header'>
                  <tr style={{fontWeight: '500'}}>
                    {tableHearderField?.map((item: any, index: any) => {
                      return (
                        <th className='ps-3' key={index}>
                          <div onClick={() => { item?.value ? onChangeFilter(item?.asc_desc, item?.value) : '' }} style={{cursor: item?.style}}>
                            {item.label}
                            {item?.value && item.asc_desc !== undefined &&
                              <span>
                                {item.asc_desc ? <Icon icon="raphael:arrowdown" /> : <Icon icon="raphael:arrowup" />}                                                            
                              </span>
                            }
                          </div>
                        </th>
                      )
                    })}
                  </tr>
                </thead>
                <tbody>
                  {bookingData?.map((item: any, i: any) => {
                    return (
                      <tr key={i} style={{borderBottom: '1px solid rgba(0, 0, 0, .12)'}}>
                        <td className='ps-3' style={{maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                          <div onClick={() => navigate(`/booking/${item?.id}/edit`)} className='fw-bold cursor-pointer fs-6 theme-color' >
                            {item?.booking_number}
                          </div>
                          <span>{item?.job_status?.label}</span>
                        </td>
                        <td style={{maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                          <div>{item?.job_name}</div>
                        </td>
                        <td style={{maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                          <div>{item?.customer_name}</div>
                        </td>
                        <td style={{maxWidth: '150px'}}>
                          <div>
                            { item?.pickup_locations }
                          </div>
                          {/* <div className='d-flex'>
                            {item?.tags?.map((item: any, index: any) => {
                              return (
                                <span key={index} className='px-2 me-2' style={{color: 'white', backgroundColor: item?.color}}>{item?.name}</span>
                              )
                            })}
                          </div> */}
                        </td>
                        <td style={{maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                          <div>
                            {/* { item?.coachiejoblegs?.length ?
                                (item?.coachiejoblegs?.length > 1 ? 
                                ((item?.coachiejoblegs[0]?.start_at_date ? moment(item?.coachiejoblegs[0]?.start_at_date).format('DD-MM-YYYY') : '') + ' - ' + (item?.coachiejoblegs[item?.coachiejoblegs?.length - 1]?.start_at ? moment(item?.coachiejoblegs[item?.coachiejoblegs?.length - 1]?.start_at_date).format('DD-MM-YYYY') : '')) : 
                                (item?.coachiejoblegs[0]?.start_at_date ? moment(item?.coachiejoblegs[0]?.start_at_date).format('DD-MM-YYYY') : '')) 
                              : ''
                            } */}
                            {item?.jobs_start_at?.map((data: any, index: any) => {
                              return `${ data ? moment(data, 'YYYY-MM-DD').format('DD-MM-YYYY') : ''}${index < item?.jobs_start_at.length - 1 ? " - " : ""}`
                            })}
                          </div>
                        </td>
                        <td style={{maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                          <div>{item?.job_count} </div>
                        </td>
                        <td style={{maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                          <div>{item?.price_by_job_legs ? item?.job_leg_sum : item?.job_price}</div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
              {bookingData?.length === 0 ?
                <p className='d-flex justify-content-center'>There are no Bookings to display</p>
                : ''
              }
            </div>
          </UILoader>
        </KTCardBody>
      </KTCard>
    </>
  )
}

export {JobPage}
