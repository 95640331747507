import React, { useEffect, useState } from 'react'
import { KTCard, KTCardBody, KTIcon } from '../../../_metronic/helpers'
import ComponentSpinner from '../../modules/apps/user-management/users-list/components/loading/Loading-spinner'
import { useNavigate } from 'react-router-dom'
import { Col, Row, Table } from 'reactstrap'
import { TagsService } from '../../modules/Services/Tags'
import toast from 'react-hot-toast'
import UILoader from '../../modules/commonModel/Ui-loader'

function TagPage() {
    const [pending, setPending] = useState(false)
    const navigate = useNavigate()
    const [tagsData, setTagsData] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [renderElementService, setRenderElementService] = useState(1)
    const [nextPage, setNextPage] = useState(null)
    const entity_id = localStorage.getItem('entity_id')

    const getTagData = () => {
        setPending(true)
        TagsService.getTagsList(entity_id, {
            per_page: 10,
            page: currentPage
        })
        .then((res: any) => { 
            if (currentPage > 1) setTagsData(pre => [...pre, ...res?.data?.response?.data]) 
            else setTagsData(res?.data?.response?.data)
            setNextPage(res?.data?.response?.next_page_url)
        })
        .catch((err: any) => {
            toast.error(err?.response?.data?.message)
        })
        .finally(() => setPending(false))
    }

    useEffect(() => {
        if (renderElementService) {
            setRenderElementService(0)
            getTagData()
        }
    }, [renderElementService])
    
    function handleScroll(event: any) {
        const scrollHeight = event.target.scrollTop + event.target.clientHeight
        const elementHeight = event.target.scrollHeight
        if (event.target.scrollTop > 0) {
            if (((scrollHeight + 100) >= elementHeight) && nextPage && !pending && !renderElementService) {
                setCurrentPage((p: any) => p + 1)
                setRenderElementService((prev) => prev + 1)
            }
        }
    }

  return (
    <>
        <KTCard>
            <KTCardBody className='p-0 pb-5 location_table'>
                <div className='invoice-list-table-header w-100 px-10 my-5'>
                    <Row xl="12" lg="12" md="12" sm="12" xs="12">
                        <Col className='d-flex align-items-center justify-content-end p-0'>
                            <div className="d-flex flex-row align-items-center">
                                <a href='#' className='btn btn-sm btn-primary' onClick={() => navigate('/tags/add')}>
                                    <KTIcon iconName='plus' className='fs-3' />
                                    New Tag
                                </a>
                            </div>
                        </Col>
                    </Row>
                </div>
                <UILoader blocking={ pending } loader={<ComponentSpinner />} overlayColor='rgba(245, 245, 240, .1)' className={pending ? 'loader-blur' : ''}>
                    <div onScroll={(e) => handleScroll(e)} id='booking_table' className='jobs-table-style'>
                        <Table className='jobs-table'>
                            <thead className='sticky-header'>
                                <tr style={{fontWeight: '500'}}>
                                    <th className='ps-3'>Name</th>
                                    <th>Color</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tagsData?.map((item: any, i: any) => {
                                    return (
                                        <tr key={i} style={{borderBottom: '1px solid rgba(0, 0, 0, .12)'}}>
                                            <td className='ps-3'>
                                                <span onClick={() => navigate(`/tags/${item?.id}/edit`)} className='fw-bold cursor-pointer fs-6 theme-color'>
                                                    {item?.name}
                                                </span>
                                            </td>
                                            <td>
                                                <span style={{color:'white', width:'250px', backgroundColor:item?.color, padding:'10px', textAlign: 'center' }}>
                                                    {item?.color}
                                                </span>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                        {tagsData?.length === 0 ?
                            <p className='d-flex justify-content-center'>There are no Tags to display</p>
                            : ''
                        }
                    </div>
                </UILoader>
            </KTCardBody>
        </KTCard>
    </>
  )
}

export default TagPage