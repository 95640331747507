import Popover from '@mui/material/Popover';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { StaticDateRangePicker } from '@mui/x-date-pickers-pro/StaticDateRangePicker';
import { PickersShortcutsItem } from '@mui/x-date-pickers/PickersShortcuts';
import { DateRange } from '@mui/x-date-pickers-pro/models';
import { IconComponent } from '../../../_metronic/helpers/components/IconComponent';
import React, { useState } from 'react';
import { Button, Col, Input, Row } from 'reactstrap';
import moment from 'moment';

function MultiDatePickr({ handleClearDate, handleDateChange, date, setSelectedDateRange, selectedDateRange }) {

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const shortcutsItems: PickersShortcutsItem<DateRange<Dayjs>>[] = [
        {
            label: 'Today',
            getValue: () => {
                const today = dayjs();
                return [today, today];
            },
        },
        {
            label: 'Yesterday',
            getValue: () => {
                const yesterday = dayjs().subtract(1, 'day');
                return [yesterday, yesterday];
            },
        },
        {
            label: 'Last 7 days',
            getValue: () => {
                const today = dayjs();
                return [today.subtract(7, 'day'), today];
            },
        },
        {
            label: 'Last 30 days',
            getValue: () => {
                const today = dayjs();
                return [today.subtract(30, 'day'), today];
            },
        },
        {
            label: 'Last 90 days',
            getValue: () => {
                const today = dayjs();
                return [today.subtract(90, 'day'), today];
            },
        },
        {
            label: 'Last 365 days',
            getValue: () => {
                const today = dayjs();
                return [today.subtract(365, 'day'), today];
            },
        },
        {
            label: 'Last month',
            getValue: () => {
                const today = dayjs();
                const startOfLastMonth = today.subtract(1, 'month').startOf('month');
                const endOfLastMonth = today.subtract(1, 'month').endOf('month');
                return [startOfLastMonth, endOfLastMonth];
            },
        },
        {
            label: 'Last 12 months',
            getValue: () => {
                const today = dayjs();
                return [today.subtract(12, 'month').startOf('day'), today];
            },
        },
        {
            label: 'Last year',
            getValue: () => {
                const today = dayjs();
                return [today.subtract(1, 'year').startOf('day'), today];
            },
        },
        { label: 'Reset', getValue: () => [null, null] },
    ];

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDateRangeChange = (newValue: DateRange<Dayjs>) => {
        setSelectedDateRange(newValue);
    };

    return (
        <>
            <div style={{ position: 'relative', display: 'inline-block' }} className='w-100'>
                <Input onClick={handleClick} value={date[0] ? (moment(date[0]?.toISOString()).format("DD-MM-YYYY") +
                    " To " + moment(date[1]?.toISOString()).format("DD-MM-YYYY")) : ''} placeholder='Select Date..' readOnly />
                {date[0] && (
                    <IconComponent
                        iconName='cross'
                        className='fs-3'
                        iconType={undefined}
                        onClick={handleClearDate}
                        style={{
                            border: '1px solid black',
                            borderRadius: '10px',
                            color: 'black',
                            position: 'absolute',
                            right: '10px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            cursor: 'pointer',
                        }}
                    />
                )}
            </div>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <StaticDateRangePicker
                        value={selectedDateRange}
                        onChange={handleDateRangeChange}
                        slotProps={{
                            shortcuts: {
                                items: shortcutsItems,
                            },
                            actionBar: { actions: [] },
                        }}
                        calendars={2}
                    />
                </LocalizationProvider>
                <Row>
                    <Col className='mx-5 mb-5 d-flex justify-content-end'>
                        <Button
                            size="sm"
                            className='me-4'
                            color="primary"
                            onClick={() => {
                                handleDateChange()
                                setAnchorEl(null);
                            }}
                        >
                            Apply
                        </Button>
                        <Button
                            size="sm"
                            color="secondary"
                            onClick={() => {
                                setAnchorEl(null);
                            }}
                        >
                            Cancel
                        </Button>
                    </Col>
                </Row>
            </Popover>
        </>
    )
}

export default MultiDatePickr