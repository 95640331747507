import React, { Fragment, useContext, useEffect, useState } from 'react'
import { KTCard, KTCardBody, KTIcon } from '../../../_metronic/helpers'
import ComponentSpinner from '../../modules/apps/user-management/users-list/components/loading/Loading-spinner'
import moment from 'moment'
import toast from 'react-hot-toast'
import { InvoiceService } from '../../modules/Services/Invoice'
import InvoiceModel from './InvoiceModel'
import UILoader from '../../modules/commonModel/Ui-loader'
import { Button, Col, Input, Row, Table } from 'reactstrap'
import AsyncSelect from 'react-select/async'
import { CustomerService } from '../../modules/Services/Customers'
import { FilterDetails } from '../../App'
import ReactSelect from 'react-select'

import Popover from '@mui/material/Popover';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { StaticDateRangePicker } from '@mui/x-date-pickers-pro/StaticDateRangePicker';
import { PickersShortcutsItem } from '@mui/x-date-pickers/PickersShortcuts';
import { DateRange } from '@mui/x-date-pickers-pro/models';
import { IconComponent } from '../../../_metronic/helpers/components/IconComponent'
import { customSelectStyles, formatCurrency, selectThemeColors } from '../../modules/commonModel/Utils'
import MultiDatePickr from '../../modules/commonModel/MultiDatePickr'

function InvoicePage() {
    const entity_id = localStorage.getItem('entity_id')
    const [filterValue, setFilterValue] = useContext<any>(FilterDetails)
    const [pending, setPending] = useState(false)
    const [invoiceData, setInvoiceData] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [openInvoice, setOpenInvoice] = useState(false)
    const [invoiceId, setInvoiceId] = useState(false)
    const [renderElementService, setRenderElementService] = useState(1)
    const [nextPage, setNextPage] = useState(null)

    const [firstTime, setFirstTime] = useState(false)
    const [searchTerm, setSearchTerm] = useState(Object.keys(filterValue?.invoice?.invoice_search)?.length ? filterValue?.invoice?.invoice_search : '')
    const [statusData, setStatusData] = useState([])
    const [currentStatus, setCurrentStatus] = useState((Object.keys(filterValue?.invoice?.status).length > 0) ? filterValue?.invoice?.status : { value: null, label: 'Select Status' })
    const [currentCustomer, setCurrentCustomer] = useState((Object.keys(filterValue?.invoice?.customer).length > 0) ? filterValue?.invoice?.customer : { value: '', label: 'Select Client' })
    let customerTimeoutId: ReturnType<typeof setTimeout> | null = null;

    // const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [selectedDateRange, setSelectedDateRange] = React.useState<DateRange<Dayjs>>((Object.keys(filterValue?.invoice?.date).length > 0) ? filterValue?.invoice?.date : [null, null]);
    const [invoiceDate, setInvoiceDate] = useState((Object.keys(filterValue?.invoice?.date).length > 0) ? filterValue?.invoice?.date : [null, null]);

    // const shortcutsItems: PickersShortcutsItem<DateRange<Dayjs>>[] = [
    //     {
    //         label: 'Today',
    //         getValue: () => {
    //             const today = dayjs();
    //             return [today, today];
    //         },
    //     },
    //     {
    //         label: 'Yesterday',
    //         getValue: () => {
    //             const yesterday = dayjs().subtract(1, 'day');
    //             return [yesterday, yesterday];
    //         },
    //     },
    //     {
    //         label: 'Last 7 days',
    //         getValue: () => {
    //             const today = dayjs();
    //             return [today.subtract(7, 'day'), today];
    //         },
    //     },
    //     {
    //         label: 'Last 30 days',
    //         getValue: () => {
    //             const today = dayjs();
    //             return [today.subtract(30, 'day'), today];
    //         },
    //     },
    //     {
    //         label: 'Last 90 days',
    //         getValue: () => {
    //             const today = dayjs();
    //             return [today.subtract(90, 'day'), today];
    //         },
    //     },
    //     {
    //         label: 'Last 365 days',
    //         getValue: () => {
    //             const today = dayjs();
    //             return [today.subtract(365, 'day'), today];
    //         },
    //     },
    //     {
    //         label: 'Last month',
    //         getValue: () => {
    //             const today = dayjs();
    //             const startOfLastMonth = today.subtract(1, 'month').startOf('month');
    //             const endOfLastMonth = today.subtract(1, 'month').endOf('month');
    //             return [startOfLastMonth, endOfLastMonth];
    //         },
    //     },
    //     {
    //         label: 'Last 12 months',
    //         getValue: () => {
    //             const today = dayjs();
    //             return [today.subtract(12, 'month').startOf('day'), today];
    //         },
    //     },
    //     {
    //         label: 'Last year',
    //         getValue: () => {
    //             const today = dayjs();
    //             return [today.subtract(1, 'year').startOf('day'), today];
    //         },
    //     },
    //     { label: 'Reset', getValue: () => [null, null] },
    // ];
    // const open = Boolean(anchorEl);
    // const id = open ? 'simple-popover' : undefined;

    const getInvoice = () => {
        setPending(true)
        InvoiceService.getInvoiceList(entity_id, {
            per_page: 20,
            page: currentPage,
            invoice_number: searchTerm ? searchTerm : null,
            customer_id: currentCustomer?.value ? currentCustomer?.value : null,
            status: currentStatus?.value ? currentStatus?.value : null,
            start_date : invoiceDate?.length ? (invoiceDate[0] ? moment(invoiceDate[0]?.toISOString()).format("YYYY-MM-DD") : null) : null,
            end_date : invoiceDate?.length ? (invoiceDate[1] ? moment(invoiceDate[1]?.toISOString()).format("YYYY-MM-DD") : null) : null,
        })
        .then((res: any) => { 
            if (currentPage > 1) setInvoiceData(pre => [...pre, ...res?.data?.response?.data]) 
            else setInvoiceData(res?.data?.response?.data)
            setNextPage(res?.data?.response?.next_page_url)
            setFirstTime(true)
        })
        .catch((err: any) => {
            console.log(err?.response?.data?.message)
        })
        .finally(() => setPending(false))
    }

    function downloadInvoicePdf(invoice_id: any) {
        InvoiceService.downloadInvoicePdf(entity_id, invoice_id)
        .then((res: any) => { 
            toast.success(res?.data?.message)
            window.open(res?.data?.url, '_blank', 'noopener,noreferrer') 
        })
        .catch((err: any) => {
            toast.error(err?.response?.data?.message)
        })
    }

    useEffect(() => {
        if (renderElementService) {
            setRenderElementService(0)
            getInvoice()
        }
    }, [renderElementService])
    
    function handleScroll(event: any) {
        const scrollHeight = event.target.scrollTop + event.target.clientHeight
        const elementHeight = event.target.scrollHeight
        if (event.target.scrollTop > 0) {
            if (((scrollHeight + 100) >= elementHeight) && nextPage && !pending && !renderElementService) {
                setCurrentPage((p: any) => p + 1)
                setRenderElementService((prev) => prev + 1)
            }
        }
    }

    useEffect(() => {
        if (searchTerm) {
            const delayDebounceFn = setTimeout(() => {
                scrollTop()
            }, 1000);
            return () => clearTimeout(delayDebounceFn)
        } else {
            if (firstTime) scrollTop()
        }
    }, [searchTerm])

    const handleSearch = (val: any) => {
        setSearchTerm(val)
        setFilterValue({...filterValue, bookings: {...filterValue?.invoice, invoice_search: val }})
    }

    function getStatusList() {
        InvoiceService.getInvoiceStatusList(entity_id)
        .then((res: any) => {
            const customerList = res?.data?.response?.map((item: any) => {
                return { value: item?.id, label: item?.label }
            })
            const dafault = { value: null, label: 'Select Status'}
            setStatusData([dafault, ...customerList])
        })
        .catch((err: any) => {
            toast.error(err?.response?.data?.message)
        })
    }

    useEffect(() => {
        getStatusList()
    }, [])

    const loadOptions = (inputValue: any, callback: any) => {
        if (customerTimeoutId) {
            clearTimeout(customerTimeoutId);
        }
        customerTimeoutId = setTimeout(() => {
            const obj = { 
                getall: 1,
                fields: 'id,customer_name',
                customer_name : inputValue
            }
            CustomerService.getCustomerList(entity_id, obj)
            .then((res: any) => {
                callback(res?.data?.response?.map((item: any) => {
                    return { value: item?.id, label: item?.customer_name}
                }))
            })
            .catch(err => console.log(err?.response?.data?.message))
        }, 1000)
    }

    function scrollTop() {
        const table = document.getElementById('invoice_table')
        table.scrollTo(0, 0)
        setCurrentPage(1)
        setRenderElementService(1)
    }

    // const handleClick = (event: any) => {
    //     setAnchorEl(event.currentTarget);
    // };
    
    // const handleClose = () => {
    //     setAnchorEl(null);
    // };
    
    // const handleDateRangeChange = (newValue: DateRange<Dayjs>) => {
    //     setSelectedDateRange(newValue);
    // };
    
    const handleClearDate = () => {    
        scrollTop();
        setInvoiceDate([null, null]);
        setSelectedDateRange([null, null]);
        setFilterValue({...filterValue, invoice: {...filterValue?.invoice, date: [null, null]}})
    };

    const handleDateChange = () => {
        setInvoiceDate(selectedDateRange)
        setFilterValue({ ...filterValue, invoice: { ...filterValue?.invoice, date: selectedDateRange } })
        scrollTop()
    }

  return (
    <Fragment>
        <InvoiceModel 
            openInvoice={openInvoice}
            setOpenInvoice={setOpenInvoice}
            invoiceId={invoiceId}
            setInvoiceId={setInvoiceId}
            setRenderElementService={setRenderElementService}
            bookingData={null}
        />
        <KTCard>
            <KTCardBody className='p-0 invoice_table'>
                <UILoader blocking={ pending } loader={<ComponentSpinner />} overlayColor='rgba(245, 245, 240, .1)' className={pending ? 'loader-blur' : ''}>
                    <div className='invoice-list-table-header w-100 px-5 my-5'>
                        <Row>
                            <Col md='3'>
                                <Input type='search' placeholder='Search' value={searchTerm} onChange={(e) => handleSearch(e?.target?.value)} />
                            </Col>
                            <Col md='3'>
                                <AsyncSelect
                                    isClearable={true}
                                    className='react-select w-100'
                                    classNamePrefix='select'
                                    placeholder='Select Client..'
                                    isRtl={false}
                                    name='callback-react-select'
                                    styles={customSelectStyles(null)}
                                    theme={selectThemeColors}
                                    loadOptions={loadOptions}
                                    value={currentCustomer}
                                    cacheOptions
                                    onChange={(data: any) => {
                                        scrollTop()
                                        setCurrentCustomer(data)
                                        setFilterValue({...filterValue, invoice: {...filterValue?.invoice, customer: data || { value: '', label: 'Select Client' }}})
                                    }}
                                />
                            </Col>
                            <Col md='3'>
                                <ReactSelect
                                    isClearable={false}
                                    isSearchable={false}
                                    className='react-select w-100 text-start'
                                    classNamePrefix='select'
                                    styles={customSelectStyles(null)}
                                    theme={selectThemeColors}
                                    options={statusData}
                                    value={currentStatus}
                                    onChange={(data: any) => {
                                        scrollTop()
                                        setCurrentStatus(data)
                                        setFilterValue({...filterValue, invoice: {...filterValue?.invoice, status: data}})
                                    }}
                                />
                            </Col>
                            <Col md='3'>
                            <MultiDatePickr
                                handleClearDate={handleClearDate}
                                handleDateChange={handleDateChange}
                                date={invoiceDate}
                                selectedDateRange={selectedDateRange}
                                setSelectedDateRange={setSelectedDateRange}
                            />
                                {/* <div style={{ position: 'relative', display: 'inline-block' }} className='w-100'>
                                    <Input onClick={handleClick} value={invoiceDate[0] ? (moment(invoiceDate[0]?.toISOString()).format("DD-MM-YYYY") +
                                        " To " + moment(invoiceDate[1]?.toISOString()).format("DD-MM-YYYY")) : ''} placeholder='Select Date..' readOnly />
                                        {invoiceDate[0] && (
                                            <IconComponent
                                                iconName='cross'
                                                className='fs-3'
                                                iconType={undefined} 
                                                onClick={handleClearDate}
                                                style={{
                                                    border: '1px solid black',
                                                    borderRadius: '10px',
                                                    color: 'black',
                                                    position: 'absolute',
                                                    right: '10px',
                                                    top: '50%',
                                                    transform: 'translateY(-50%)',
                                                    cursor: 'pointer',
                                                }}
                                            />
                                        )}
                                </div> */}
                                {/* <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                >
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <StaticDateRangePicker
                                            value={selectedDateRange}
                                            onChange={handleDateRangeChange}
                                            slotProps={{
                                            shortcuts: {
                                                items: shortcutsItems,
                                            },
                                            actionBar: { actions: [] },
                                            }}
                                            calendars={2}
                                        />
                                    </LocalizationProvider>
                                    <Row>
                                        <Col className='mx-5 mb-5 d-flex justify-content-end'>
                                            <Button
                                                size="sm"
                                                className='me-4'
                                                color="primary"
                                                onClick={() => {
                                                    setAnchorEl(null);
                                                    setInvoiceDate(selectedDateRange)
                                                    setFilterValue({...filterValue, invoice: {...filterValue?.invoice, date: selectedDateRange}})
                                                    scrollTop()
                                                }}
                                            >
                                                Apply
                                            </Button>
                                            <Button
                                                size="sm"
                                                color="secondary"
                                                onClick={() => {
                                                    setAnchorEl(null);
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                        </Col>
                                    </Row>
                                </Popover> */}
                            </Col>
                        </Row>
                    </div>
                    <div onScroll={(e) => handleScroll(e)} id='invoice_table' className='invoice-table-style'>
                        <Table className='jobs-table'>
                            <thead className='sticky-header'>
                                <tr style={{fontWeight: '500'}}>
                                    <th className='ps-3'>Invoice Number</th>
                                    <th>Client</th>
                                    <th>Date</th>
                                    <th>Status</th>
                                    <th className='text-end'>Invoice Amount</th>
                                    <th className='text-end'>Paid</th>
                                    <th className='text-end'>Balance</th>
                                    <th className='text-end pe-3'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {invoiceData?.map((item: any, i: any) => {
                                    return (
                                        <tr key={i} style={{borderBottom: '1px solid rgba(0, 0, 0, .12)'}}>
                                            <td className='ps-3'>
                                                <span className='fw-bold cursor-pointer fs-6 theme-color' onClick={() => { setInvoiceId(item?.id); setOpenInvoice(true) }}>
                                                    {item?.invoice_number}
                                                </span>
                                            </td>
                                            <td>
                                                <div>{item?.customer_name}</div>
                                            </td>
                                            <td>
                                                <div>{item?.date ? moment(item?.date).format('DD-MM-YYYY') : ''} </div>
                                            </td>
                                            <td>
                                                <div>{item?.status?.label} </div>
                                            </td>
                                            <td style={{width: '120px'}}>
                                                <div className='d-flex justify-content-end'>{formatCurrency(item?.amount)}</div>
                                            </td>
                                            <td>
                                                <div className='d-flex justify-content-end'>{formatCurrency(item?.paid)}</div>
                                            </td>
                                            <td>
                                                <div className='d-flex justify-content-end'>{formatCurrency(item?.balance)}</div>
                                            </td>
                                            <td className='pe-3 py-0'>
                                                <div className='d-flex justify-content-end flex-shrink-0 pt-2'>
                                                    <a onClick={() => downloadInvoicePdf(item?.id)} className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'>
                                                        <KTIcon iconName='folder-down' className='fs-3' />
                                                    </a>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                        {invoiceData?.length === 0 ?
                            <p className='d-flex justify-content-center'>There are no invoices to display</p>
                            : ''
                        }
                    </div>
                </UILoader>
            </KTCardBody>
        </KTCard>
    </Fragment>
  )
}

export default InvoicePage