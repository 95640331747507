import React, { Fragment, useEffect, useState } from 'react'
import { Col, Card, Form, Input, Label, Row, Button, Spinner } from "reactstrap"
import { Controller, useForm } from "react-hook-form"
import { useNavigate, useParams } from 'react-router'
import toast from 'react-hot-toast'
import ConfirmModal from '../../modules/commonModel/ConfirmModal'
import UILoader from '../../modules/commonModel/Ui-loader'
import ComponentSpinner from '../../modules/apps/user-management/users-list/components/loading/Loading-spinner'
import { UsersService } from '../../modules/Services/Users'
import { customSelectStyles, selectThemeColors } from '../../modules/commonModel/Utils'
import ReactSelect from 'react-select'
import { userInfo } from '../../modules/auth/core/_requests'
import { useAuth } from '../../modules/auth'

function AddUser() {

    const { control, handleSubmit, reset, formState: { errors } } = useForm()
    const {currentUser, setCurrentUser, logout} = useAuth()
    const entity_id = localStorage.getItem('entity_id')
    const { user_id } = useParams()
    const navigate = useNavigate()
    const [loader, setLoader] = useState(false)
    const [centeredModal, setCenteredModal] = useState(false)
    const [pending, setPending] = useState('')
    const [currentLevel, setCurrentLevel] = useState({ value: '', label: 'Select Level' })
    const [levelData, setLevelData] = useState([{ value: '', label: 'Select Level' }])
    const [currentStatus, setCurrentStatus] = useState(1)

    const userLevel = () => {
        UsersService.userLevelList(entity_id)
            .then((res: any) => {
                const data = res?.data?.response?.map((item: any) => {
                    return { value: item?.id, label: item?.name }
                })
                setLevelData(data)
            })
            .catch((err: any) => {
                toast.error(err?.response?.data?.message)
            })
            .finally(() => setLoader(false))
    }

    useEffect(() => {
        userLevel()
    }, [])

    function fetchFormData(data: any) {
        setLoader(true)
        const obj = {
            entity_id: entity_id,
            first_name: data?.first_name,
            last_name: data?.last_name,
            email: data?.email,
            level: data?.level?.value,
            status: currentStatus
        }
        if (user_id) delete obj.entity_id

        UsersService[user_id ? 'updateUser' : 'storeUser'](entity_id, obj, user_id)
            .then((res: any) => {
                toast.success(res?.data?.message)
                navigate('/users')
                userInfo(localStorage.getItem('entity_id'))
                    .then((res: any) => { 
                        setCurrentUser(res?.data)
                        localStorage.setItem('auth_user', JSON.stringify(res?.data))
                    })
                    .catch((err: any) => {
                        toast.error(err?.response?.data?.message)
                        logout()
                    })
            })
            .catch((err: any) => {
                toast.error(err?.response?.data?.message)
            })
            .finally(() => setLoader(false))
    }

    function editData() {
        setPending('edit')
        UsersService.editUser(entity_id, user_id)
            .then((res: any) => {
                const data = res.data?.response
                const obj: any = {
                    first_name: data?.first_name,
                    last_name: data?.last_name,
                    email: data?.email,
                    level: { value: data?.level, label: data?.user_level }
                }
                reset(obj)
                setCurrentStatus(data?.is_activated)
            })
            .catch((err: any) => {
                toast.error(err?.response?.data?.message)
            })
            .finally(() => setPending(''))
    }

    useEffect(() => {
        if (user_id) editData()
    }, [user_id])

    function forceDeleteUser() {
        setPending('delete')
        UsersService.deleteUser(entity_id, user_id)
            .then((res: any) => {
                toast.success(res?.data?.message)
                setCenteredModal(false)
                navigate('/users')
            })
            .catch((err: any) => {
                toast.error(err?.response?.data?.message)
            })
            .finally(() => setPending(''))
    }

    return (
        <Fragment>
            <ConfirmModal
                isOpen={centeredModal}
                onHide={() => setCenteredModal(false)}
                ondelete={forceDeleteUser}
                loader={pending === 'delete'}
            />
            <Card className='p-10'>
                <UILoader blocking={pending === 'edit'} loader={<ComponentSpinner />} overlayColor='rgba(245, 245, 240, .1)' className={pending === 'edit' ? 'loader-blur' : ''}>
                    <Form onSubmit={handleSubmit(fetchFormData)}>
                        <Row>
                            <Col md='6' className='mb-6'>
                                <Label className='form-label' for='name'>First Name</Label>
                                <Controller
                                    name='first_name'
                                    control={control}
                                    rules={{ required: true }}
                                    defaultValue=''
                                    render={({ field }) =>
                                        <Input
                                            placeholder='Enter Name'
                                            invalid={errors.first_name && true}
                                            {...field}
                                        />
                                    }
                                />
                                {errors.first_name && (<small className="text-danger ml-2 ">First name is a required field</small>)}
                            </Col>
                            <Col md='6' className='mb-6'>
                                <Label className='form-label' for='name'>Last Name</Label>
                                <Controller
                                    name='last_name'
                                    control={control}
                                    rules={{ required: true }}
                                    defaultValue=''
                                    render={({ field }) =>
                                        <Input
                                            placeholder='Enter Name'
                                            invalid={errors.last_name && true}
                                            {...field}
                                        />
                                    }
                                />
                                {errors.last_name && (<small className="text-danger ml-2 ">Last name is a required field</small>)}
                            </Col>
                            <Col md='6' className='mb-6'>
                                <Label className='form-label' for='email'>Email</Label>
                                <Controller
                                    name='email'
                                    control={control}
                                    defaultValue=''
                                    render={({ field }) =>
                                        <Input
                                            placeholder='Enter Email'
                                            {...field}
                                        />
                                    }
                                />
                            </Col>
                            <Col md='6' className='mb-6'>
                                <Label className='form-label' for='level'>Level</Label>
                                <Controller
                                    name='level'
                                    control={control}
                                    defaultValue=''
                                    render={({ field }) =>
                                        <ReactSelect
                                            {...field}
                                            isClearable={false}
                                            className='react-select'
                                            classNamePrefix='select'
                                            styles={customSelectStyles(null)}
                                            theme={selectThemeColors}
                                            options={levelData}
                                            onChange={(data: any) => {
                                                field.onChange(data)
                                            }}
                                        />
                                    }
                                />
                            </Col>
                            {user_id &&
                                <Col md='6' className='mb-6'>
                                    <Label className='form-label' for='level'>Status</Label>
                                    <div className='d-flex mt-2'>
                                        <div className='me-10 d-flex align-items-center'>
                                            <Label className='form-label mb-0' for=''>Active</Label>
                                            <input
                                                className='form-check-input ms-3'
                                                type='radio'
                                                name='status'
                                                checked={currentStatus === 1}
                                                onChange={() => setCurrentStatus(1)}
                                            />
                                        </div>
                                        <div className='d-flex align-items-center'>
                                            <Label className='form-label mb-0' for='model'>In Active</Label>
                                            <input
                                                className='form-check-input ms-3'
                                                type='radio'
                                                name='status'
                                                checked={currentStatus === 0}
                                                onChange={() => setCurrentStatus(0)}
                                            />
                                        </div>
                                    </div>
                                </Col>
                            }
                        </Row>
                        <div className='d-flex justify-content-between mt-5'>
                            <div>
                                {(Number(user_id) != currentUser?.id) &&
                                    (currentUser?.level == 1 && user_id) &&
                                        <Button type="button" color="danger" onClick={() => setCenteredModal(true)}>
                                            Delete
                                        </Button>
                                }
                            </div>
                            <div>
                                <Button type="button" color="secondary" onClick={() => navigate("/users")} className='me-2'>
                                    Close
                                </Button>
                                <Button type='submit' color='primary' disabled={loader} className='btn-next'>
                                    {loader ? <Spinner size='sm' /> : <span className='align-middle d-sm-inline-block d-none'>Save</span>}
                                </Button>
                            </div>
                        </div>
                    </Form>
                </UILoader>
            </Card>
        </Fragment>
    )
}

export default AddUser