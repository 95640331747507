  export const selectThemeColors = (theme: any) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: 'rgba(251, 236, 208, 0.6)', // for option hover bg-color
      primary: 'rgb(238, 152, 65)', // for selected option bg-color
      neutral20: '#DBDFE9', // for input border-color
      neutral30: '#DBDFE9' // for input hover border-color
    }
  })

  export const customSelectStyles = (hasError: any) => ({
    control: (base: any, state: any) => ({
      ...base,
      borderColor: hasError ? "#ea5455" : "#DBDFE9", // Red border if error exists,
      backgroundColor : state.isDisabled ? '#e6e6e6' : '',
      color : state.isDisabled ? '#b9b9c3' : '',
      cursor : state.isDisabled ? 'not-allowed' : ''
    }),
    dropdownIndicator: (base: any) => ({
      ...base,
      color: "#6d6a7a",
    }),
    option: (base: any, state: any) => ({
      ...base,
      color: state.isSelected ? "white" : state.isFocused ? "rgb(255, 128, 0)" : "#4B5675" // Change color on hover
    }),
    singleValue: (base: any) => ({
      ...base,
      color: "#4B5675",
    }),
  });

  export const selectStyle = {
    control: (style: any) => ({ ...style, border: '1px solid #ea5455'})
  }

  export const currentEntity = () => {
    const auth_user = JSON.parse(localStorage.getItem('auth_user'))
    const entity_id = localStorage.getItem('entity_id')

    let entityData = null
    auth_user?.entities?.map((item: any) => {
      if (item?.id === Number(entity_id)) entityData = item
    })
    return entityData
  }

  export function preventDefualt(e: any) {
    if (e.key == '-' || e.key == '+' || e.key == '*' || e.key == '/' || e.key == 'e' || e.key == '.' || e.keyCode === 32  || e.keyCode === 13) {
      e.preventDefault();
      return false;
    }
  }

  export function preventOnEnter(e: any) {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent default form submission
    }
  }

  export function formatCurrency(value: any) {
    return value?.toLocaleString("en-US", {style:"currency", currency:"IND"}).replace("IND", '').trim('')
  }