import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Button, CardTitle, Col, Form, Input, Label, Modal, ModalBody, Row, Spinner } from 'reactstrap'
import { UsersService } from '../../modules/Services/Users'
import toast from 'react-hot-toast'
import { useAuth } from '../../modules/auth'

function ResetPasswordModal({ openResetPassword, setOpenResetPassword }) {
    const entity_id = localStorage.getItem('entity_id');
    const { control, handleSubmit, reset, formState: { errors } } = useForm()
    const [loader, setLoader] = useState('')
    const { currentUser } = useAuth()

    const onSubmit = (data: any) => {
        setLoader('update')
        const obj = {
            old_password: data?.old_password,
            new_password: data?.new_password
        }
        UsersService.resetPassword(entity_id, obj, currentUser?.id)
            .then((res: any) => {
                toast.success(res?.data?.message)
                setOpenResetPassword(false)
            })
            .catch((err: any) => toast.error(err?.response?.data?.message))
            .finally(() => setLoader(''))
    }

    useEffect(() => {
        if (!openResetPassword) {
            reset()
        }
    }, [openResetPassword])

    return (
        <Modal className="modal-dialog-centered" isOpen={openResetPassword} toggle={() => setOpenResetPassword(false)}>
            <ModalBody className="p-10">
                <div className="pb-5 d-flex justify-content-center align-items-center" >
                    <CardTitle className='dropdown-header'>Reset Password</CardTitle>
                </div>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row className="pt-5">
                        <Col xs={12} className='mb-5'>
                            <Label className='form-label' for='password'>Old Password</Label>
                            <Controller name='old_password' control={control} defaultValue='' rules={{ required: true }}
                                render={({ field }) => (
                                    <Input placeholder='Enter Old Password'
                                        type='password'
                                        invalid={errors.old_password && false}
                                        {...field}
                                    />)
                                }
                            />
                            {errors.old_password && (<small className="text-danger ml-2">Old Password is a required field</small>)}
                        </Col>
                        <Col xs={12} className='mb-5'>
                            <Label className='form-label' for='password'>New Password</Label>
                            <Controller name='new_password' control={control} defaultValue='' rules={{ required: true }}
                                render={({ field }) => (
                                    <Input placeholder='Enter New Password'
                                        type='password'
                                        invalid={errors.new_password && false}
                                        {...field}
                                    />
                                )}
                            />
                            {errors.new_password && (<small className="text-danger ml-2">New Password is a required field</small>)}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} className="mt-10">
                            <div className='d-flex justify-content-end'>
                                <Button type="button" className="btn btn-sm btn-secondary mx-3" onClick={() => setOpenResetPassword(false)}>
                                    Close
                                </Button>
                                <Button color="primary" type="submit" className='btn btn-sm' disabled={loader === 'update'}>
                                    {loader ? <Spinner size='sm' /> : <span className='align-middle d-sm-inline-block d-none'>Save</span>}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </ModalBody>
        </Modal>
    )
}

export default ResetPasswordModal