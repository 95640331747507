import {Fragment, useContext, useEffect, useState} from 'react'
import {KTCard, KTCardBody, KTIcon,} from '../../../_metronic/helpers'
import { useNavigate } from 'react-router'
import toast from 'react-hot-toast'
import ComponentSpinner from '../../modules/apps/user-management/users-list/components/loading/Loading-spinner'
import moment from 'moment'
import { Col, Input, Row, Table } from 'reactstrap'
import { DriverService } from '../../modules/Services/Drivers'
import UILoader from '../../modules/commonModel/Ui-loader'
import { FilterDetails } from '../../App'
import { Icon } from '@iconify/react';

const DriverPage = () => {
    const tableHearder = [
        {label: 'Name', asc_desc: undefined, value: 'driver_name', style: 'pointer'},
        {label: 'Email', asc_desc: undefined, value: null, style: ''},
        {label: 'Mobile', asc_desc: undefined, value: null, style: ''},
        {label: 'Created_at', asc_desc: undefined, value: null, style: ''}
    ]

    const [filterValue, setFilterValue] = useContext<any>(FilterDetails)
    const [pending, setPending] = useState(false)
    const navigate = useNavigate()
    const [driverData, setDriverData] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const entity_id = localStorage.getItem('entity_id')
    const [renderElementService, setRenderElementService] = useState(1)
    const [nextPage, setNextPage] = useState(null)
    const [searchTerm, setSearchTerm] = useState('')
    const [tableHearderField, setTableHearderField] = useState(tableHearder)
    const [sortOrder, setSortOrder] = useState((Object.keys(filterValue?.drivers?.order_by).length > 0) ? filterValue?.drivers?.order_by : null);
    const [sort, setSort] = useState((Object.keys(filterValue?.drivers?.sort_by).length > 0) ? filterValue?.drivers?.sort_by : null);

    const getDriver = () => {
        setPending(true)
        DriverService.getDriverList(entity_id, {
            per_page: 20,
            page: currentPage,
            search: searchTerm ? searchTerm : null,
            sort_by: sortOrder ? sortOrder : null,
            order_by: sort ? sort : null
        })
        .then((res: any) => { 
            if (currentPage > 1) setDriverData(pre => [...pre, ...res?.data?.response?.data]) 
            else setDriverData(res?.data?.response?.data)
            setNextPage(res?.data?.response?.next_page_url)
        })
        .catch((err: any) => {
            toast.error(err?.response?.data?.message)
        })
        .finally(() => setPending(false))
    }

    useEffect(() => {
        if (searchTerm) {
            const delayDebounceFn = setTimeout(() => {
                scrollTop()
            }, 1000);
            return () => clearTimeout(delayDebounceFn)
        } else {
            scrollTop()
        }
    }, [searchTerm])

    useEffect(() => {
        if (Object.keys(filterValue?.drivers.driver_search)?.length > 0) {
            let text = ''
            for (const item in filterValue?.drivers?.driver_search) {
                text += filterValue?.drivers?.driver_search[item]
            }
            setSearchTerm(text)
        }
    }, [filterValue])

    function handleSearch(e: any) {
        setSearchTerm(e.target.value)
        setFilterValue({...filterValue, drivers: {...filterValue?.drivers, driver_search: e.target.value }})
    }

    useEffect(() => {
        if (renderElementService) {
            setRenderElementService(0)
            getDriver()
        }
    }, [renderElementService])
    
    function handleScroll(event: any) {
        const scrollHeight = event.target.scrollTop + event.target.clientHeight
        const elementHeight = event.target.scrollHeight
        if (event.target.scrollTop > 0) {
            if (((scrollHeight + 100) >= elementHeight) && nextPage && !pending && !renderElementService) {
                setCurrentPage((p: any) => p + 1)
                setRenderElementService((prev) => prev + 1)
            }
        }
    }

    function scrollTop() {
        const table = document.getElementById('driver_table')
        table.scrollTo(0, 0)
        setCurrentPage(1)
        setRenderElementService(1)
    }

    function onChangeFilter(event: any, fieldText: any) {
        setSortOrder('asc');
        const sort = tableHearderField?.map((item: any) => {
            if(item?.value == fieldText) {
                if(event) return {...item, asc_desc: false};
                return {...item, asc_desc: true};
            } else {
                return {...item, asc_desc: undefined}
            }
        })
        setTableHearderField(sort);
        if (event) setSortOrder('desc');
        setSort(fieldText);
        scrollTop();
        setFilterValue({...filterValue, drivers: {...filterValue?.drivers, sort_by: fieldText, order_by: event ? 'desc' : 'asc'}})
    }

  return (
    <>
        <KTCard>
            <KTCardBody className='p-0 driver_table'>
                <div className='invoice-list-table-header w-100 px-10 my-5'>
                    <Row xl="2" lg="2" md="2" sm="1" xs="1">
                        <Col className='d-flex align-items-center p-0' md={6}>
                            <Col md={4}>
                                <Input type='search' placeholder='Search' value={searchTerm} onChange={handleSearch} />
                            </Col>
                        </Col>
                        <Col className='d-flex align-items-center justify-content-end p-0' md={6}>
                            <div className="d-flex flex-row align-items-center">
                                <a className='btn btn-sm btn-primary' onClick={() => navigate('/driver/add')}>
                                    <KTIcon iconName='plus' className='fs-3' />
                                    New Driver
                                </a>
                            </div>
                        </Col>
                    </Row>
                </div>
                <UILoader blocking={ pending } loader={<ComponentSpinner />} overlayColor='rgba(245, 245, 240, .1)' className={pending ? 'loader-blur' : ''}>
                    <div onScroll={(e) => handleScroll(e)} id='driver_table' className='jobs-table-style'>
                        <Table className='jobs-table'>
                            <thead className='sticky-header'>
                                <tr style={{fontWeight: '500'}}>
                                    {tableHearderField?.map((item, index) => {
                                        return (
                                            <th className='ps-3' key={index}>
                                                <div onClick={() => { item?.value ? onChangeFilter(item?.asc_desc, item?.value) : '' }} style={{cursor: item?.style}}>
                                                    {item.label}
                                                    {item?.value && item.asc_desc !== undefined &&
                                                        <span>
                                                            {item.asc_desc ? <Icon icon="raphael:arrowdown" /> : <Icon icon="raphael:arrowup" />}                                                            
                                                        </span>
                                                    }
                                                </div>
                                            </th>
                                        )
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {driverData?.map((item: any, i: any) => {
                                    return (
                                    <tr key={i} style={{borderBottom: '1px solid rgba(0, 0, 0, .12)'}}>
                                        <td className='ps-3' style={{maxWidth: '250px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                                            <span onClick={() => navigate(`/driver/${item?.id}/edit`)} className='fw-bold cursor-pointer  fs-6 theme-color'>
                                                {item?.driver_name}
                                            </span>
                                        </td>
                                        <td>
                                            <div>{item?.driver_email}</div>
                                        </td>
                                        <td>
                                            <div>{item?.driver_mobile ? (item?.driver_mobile?.length > 3 ? ("+" + item?.driver_mobile) : '') : ''} </div>
                                        </td>
                                        <td>
                                            <div>{item?.created_at ? moment(item?.created_at).format('DD-MM-YYYY') : ''}</div>
                                        </td>
                                    </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                        {driverData?.length === 0 ?
                            <p className='d-flex justify-content-center'>There are no drivers to display</p>
                            : ''
                        }
                    </div>
                </UILoader>
            </KTCardBody>
        </KTCard>
    </>
  )
}

export {DriverPage}
