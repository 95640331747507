import React from 'react'
import { Card, CardBody, CardHeader, CardTitle, Col, Form, Input, Label, Row, Spinner } from "reactstrap"
import ReactSelect from 'react-select'
import { customSelectStyles, selectThemeColors } from "../../modules/commonModel/Utils"
import { Fragment } from "react/jsx-runtime"
import { Button } from "react-bootstrap"
import UILoader from "../../modules/commonModel/Ui-loader"
import ComponentSpinner from "../../modules/apps/user-management/users-list/components/loading/Loading-spinner"
import { Controller, useForm } from "react-hook-form"
import { useEffect, useState } from "react"
import toast from "react-hot-toast"
import { LocationService } from "../../modules/Services/Location"
import { EntityService } from "../../modules/Services/Entity"
import { useDropzone } from 'react-dropzone';
import PhoneInput from "react-phone-input-2"
import { userInfo } from "../../modules/auth/core/_requests"
import { useAuth } from "../../modules/auth"
import { toAbsoluteUrl } from '../../../_metronic/helpers'

function EntityProfile() {
    const entity_id = localStorage.getItem('entity_id')
    const defaultAvatar = toAbsoluteUrl('media/avatars/avatar-blank.png');
    const { setCurrentUser, logout } = useAuth()
    const { control, handleSubmit, reset, setValue, formState: { errors } } = useForm()
    const [currentCountry, setCurrentCountry] = useState({ value: '', label: 'Select Country' })
    const [countryData, setCountryData] = useState([])
    const [currentCounty, setCurrentCounty] = useState({ value: '', label: 'Select Country' })
    const [countyData, setCountyData] = useState([])
    const [countryChange, setCountryChange] = useState(true)
    const [editList, setEditList] = useState(null)
    const [loader, setLoader] = useState(null)
    const [avatar, setAvatar] = useState<File[] | string>(defaultAvatar)
    const formData = new FormData()
    const [isChargeVat, setIsChargeVat] = useState(false)
    const [officePhone, setOfficePhone] = useState('')
    const phoneStyle = { backgroundColor: "white", backgroundClip: "padding-box", border: "1px solid #d8d6de", borderRadius: "0.357rem", width: "100%" }

    function editData() {
        setLoader('edit')
        EntityService.editEntityProfile(entity_id)
            .then((res: any) => {
                const obj = {
                    entity_name: res.data?.response?.entity_name,
                    sms_name: res.data?.response?.sms_name,
                    email: res.data?.response?.email,
                    address_1: res.data?.response?.address_1,
                    address_2: res.data?.response?.address_2,
                    city: res.data?.response?.city,
                    postCode: res.data?.response?.postcode,
                    vat_rate: res.data?.response?.vat_rate,
                    telephone: res.data?.response?.tel,
                    web: res.data?.response?.web,
                    country: '',
                    county: '',
                    start: res.data?.response?.start_time,
                    end: res.data?.response?.end_time
                }
                setOfficePhone(res.data?.response?.tel)
                setIsChargeVat(res.data?.response?.is_charge_vat)
                setEditList(res.data?.response)
                setAvatar(res?.data?.response?.business_logo)
                reset(obj)
            })
            .catch((err: any) => {
                toast.error(err?.response?.data?.message)
            })
            .finally(() => setLoader(null))
    }

    useEffect(() => {
        editData()
    }, [])

    useEffect(() => {
        if (countryChange && editList && countryData?.length >= 0 && countyData?.length >= 0) {
            countryData?.map((item: any) => {
                if (Number(editList?.country) === item?.value) {
                    setValue('country', item)
                    setCurrentCountry(item)
                }
            })
            countyData?.map((item: any) => {
                if (Number(editList?.county) === item?.value) {
                    setValue('county', item)
                    setCurrentCounty(item)
                }
            })
        }
    }, [editList, countryData, countyData])

    function getCountyList() {
        LocationService.getCountyList(entity_id, { country_id: currentCountry?.value })
            .then((res: any) => {
                const countyList = res?.data?.response?.map((item: any) => {
                    return { value: item?.id, label: item?.name }
                })
                setCountyData(countyList)
            })
            .catch((err: any) => {
                toast.error(err?.response?.data?.message)
            })
    }

    useEffect(() => {
        if (currentCountry?.value) getCountyList()
    }, [currentCountry])

    function getCountryList() {
        LocationService.getCountryList(entity_id)
            .then((res: any) => {
                const countryList = res?.data?.response?.map((item: any) => {
                    return { value: item?.id, label: item?.name }
                })
                setCountryData(countryList)
            })
            .catch((err: any) => {
                toast.error(err?.response?.data?.message)
            })
    }

    useEffect(() => {
        getCountryList()
    }, [])

    function fetchFormData(data: any) {
        setLoader('update')
        formData.append('entity_name', data?.entity_name)
        formData.append('sms_name', data?.sms_name)
        formData.append('email', data?.email ? data?.email : '')
        formData.append('address_1', data?.address_1 ? data?.address_1 : '')
        formData.append('address_2', data?.address_2 ? data?.address_2 : '')
        formData.append('city', data?.city ? data?.city : '')

        formData.append('postcode', data?.postCode ? data?.postCode : '')
        formData.append('country', currentCountry?.value)
        formData.append('county', currentCounty?.value)
        formData.append('business_logo', typeof avatar !== 'string' ? avatar[0] : '')
        formData.append('is_charge_vat', JSON.stringify(+isChargeVat))
        formData.append('vat_rate', data?.vat_rate)
        formData.append('tel', data?.telephone)
        formData.append('web', data?.web)
        formData.append('start_time', data?.start)
        formData.append('end_time', data?.end)

        EntityService.updateEntityProfile(entity_id, formData)
            .then((res: any) => {
                toast.success(res?.data?.message)

                userInfo(localStorage.getItem('entity_id'))
                    .then((res: any) => {
                        setCurrentUser(res?.data)
                        localStorage.setItem('auth_user', JSON.stringify(res?.data))
                        localStorage.setItem('entity_id', '1')
                    })
                    .catch((err: any) => {
                        toast.error(err?.response?.data?.message)
                        logout()
                    })
            })
            .catch((err: any) => {
                toast.error(err?.response?.data?.message)
            })
            .finally(() => setLoader(null))
    }

    const showImage = (e: any) => { e.currentTarget.src = defaultAvatar }

    const { getRootProps, getInputProps } = useDropzone({
        multiple: false,
        accept: {
            'image/*': [] // Allow all image types
        },
        onDrop: (acceptedFiles) => {
            if (acceptedFiles.length > 0) {
                setAvatar(acceptedFiles.map((file) => Object.assign(file)));
            }
        },
    });

    const renderFileSize = () => {
        if (typeof avatar === 'string') {
            if (avatar.endsWith('no_image.png')) return defaultAvatar;
            else return avatar;
        } else if (avatar?.length > 0) {
            return URL.createObjectURL(avatar[0]);
        } else {
            return defaultAvatar;
        }
    };

    return (
        <Fragment>
            <Card>
                <UILoader blocking={loader === 'edit'} loader={<ComponentSpinner />} overlayColor='rgba(245, 245, 240, .1)' className={loader === 'edit' ? 'loader-blur' : ''}>
                    <CardBody>
                        <CardTitle className='dropdown-header mb-5'>Entity Profile</CardTitle>
                        <Form onSubmit={handleSubmit(fetchFormData)}>
                            <Row>
                                <Col md='4' className='mb-6'>
                                    <Label className='form-label' for='name'>Name</Label>
                                    <Controller name='entity_name' control={control} rules={{ required: true }}
                                        render={({ field }) => <Input placeholder='Enter Entity Name' type="text" invalid={errors.entity_name && true} {...field} />}
                                    />
                                    {errors.entity_name && (<small className="text-danger ml-2 ">Name is a required field</small>)}
                                </Col>
                                <Col md='4' className='mb-6'>
                                    <Label className='form-label' for='email'>Sender ID</Label>
                                    <Controller name='sms_name' control={control}
                                        rules={{
                                            required: { value: true, message: "Sender ID is a required field" },
                                            minLength: {
                                                value: 3,
                                                message: "Sender ID must be at least 3 characters",
                                            },
                                            maxLength: {
                                                value: 11,
                                                message: "Sender ID must be at most 11 characters",
                                            },
                                            pattern: {
                                                value: /^[a-zA-Z0-9-]*$/,
                                                message: "Only letters, numbers, and dashes are allowed (no spaces)",
                                            },
                                            validate: {
                                                notOnlyNumbers: (value) =>
                                                    !/^\d+$/.test(value) || "Sender ID cannot be only numbers",
                                            }
                                        }}
                                        render={({ field }) => <Input placeholder='Enter Sender ID' type="text" invalid={!!errors.sms_name} {...field} />}
                                    />
                                    {errors.sms_name?.message && (
                                        <small className="text-danger ml-2 ">
                                            {errors.sms_name.message?.toString() || "Invalid input"}
                                        </small>
                                    )}
                                </Col>
                                <Col md='4' className='mb-6'>
                                    <Label className='form-label' for='Mobile'>Email</Label>
                                    <Controller name='email' control={control} rules={{ required: true }}
                                        render={({ field }) => <Input placeholder='Enter Email' type="text" invalid={errors.email && true} {...field} />}
                                    />
                                    {errors.email && (<small className="text-danger ml-2 ">Email is a required field</small>)}
                                </Col>
                                <Col md='4' className='mb-6'>
                                    <Label className='form-label'>Address Line 1</Label>
                                    <Controller name='address_1' control={control}
                                        render={({ field }) => <Input placeholder='98 Borough bridge Road, Birmingham' type="text" {...field} />}
                                    />
                                </Col>
                                <Col md='4' className='mb-6'>
                                    <Label className='form-label'>Address Line 2</Label>
                                    <Controller name='address_2' control={control}
                                        render={({ field }) => <Input placeholder='98 Borough bridge Road, Birmingham' type="text" {...field} />}
                                    />
                                </Col>
                                <Col md='4' className='mb-4'>
                                    <Label className='form-label' for='city'>City</Label>
                                    <Controller name='city' control={control} rules={{ required: false }} defaultValue=''
                                        render={({ field }) => <Input placeholder='City' {...field} />}
                                    />
                                </Col>
                                <Col md='4' className='mb-6'>
                                    <Label className='form-label' for='country'>Country</Label>
                                    <Controller name='country' control={control} rules={{ required: false }} defaultValue=''
                                        render={({ field: { onChange } }) => (
                                            <ReactSelect
                                                isClearable={false}
                                                className='react-select'
                                                classNamePrefix='select'
                                                theme={selectThemeColors}
                                                styles={customSelectStyles(errors.country)}
                                                options={countryData}
                                                value={currentCountry}
                                                onChange={(data: any) => {
                                                    onChange(data)
                                                    setCountryChange(false)
                                                    setCurrentCountry(data)
                                                    setCurrentCounty({ value: '', label: 'Select Country' })
                                                    setValue('county', '')
                                                }}
                                            />
                                        )}
                                    />
                                    {errors?.country && (<small className="text-danger ml-2 ">Country is a required field</small>)}
                                </Col>
                                <Col md='4' className='mb-6'>
                                    <Label className='form-label' for='county'>County</Label>
                                    <Controller name='county' control={control} rules={{ required: false }} defaultValue=''
                                        render={({ field: { onChange } }) => (
                                            <ReactSelect
                                                isClearable={false}
                                                className='react-select'
                                                classNamePrefix='select'
                                                styles={customSelectStyles(errors.county)}
                                                theme={selectThemeColors}
                                                options={countyData}
                                                value={currentCounty}
                                                onChange={(data: any) => {
                                                    onChange(data)
                                                    setCurrentCounty(data)
                                                }}
                                            />
                                        )}
                                    />
                                    {errors?.county && (<small className="text-danger ml-2 ">County is a required field</small>)}
                                </Col>
                                <Col md='4' className='mb-4'>
                                    <Label className='form-label' for='postCode'>Post Code</Label>
                                    <Controller name='postCode' control={control} rules={{ required: false }} defaultValue=''
                                        render={({ field }) => (
                                            <Input invalid={errors.postCode && true} name='postCode' placeholder="SW1A 2AA" styles={errors.postCode} {...field} />
                                        )}
                                    />
                                    {errors?.postCode && (<small className="text-danger ml-2 ">Post Code is a required field</small>)}
                                </Col>
                                <Col md='4' className='mb-4'>
                                    <Label className='form-label' for='postCode'>Telephone</Label>
                                    <Controller name='telephone' control={control} rules={{ required: false }} defaultValue=''
                                        render={({ field: { onChange } }) => (
                                            <PhoneInput inputStyle={phoneStyle} value={officePhone} onChange={(e: any) => { onChange(e); setOfficePhone(e) }} />
                                        )}
                                    />
                                    {errors?.telephone && (<small className="text-danger ml-2 ">Telephone is a required field</small>)}
                                </Col>
                                <Col md='4' className='mb-4'>
                                    <Label className='form-label' for='postCode'>Web</Label>
                                    <Controller name='web' control={control} rules={{ required: true }} defaultValue=''
                                        render={({ field }) => (
                                            <Input invalid={errors.web && true} placeholder="www.xyz.com" styles={errors.web} {...field} />
                                        )}
                                    />
                                    {errors?.web && (<small className="text-danger ml-2 ">web is a required field</small>)}
                                </Col>
                                <Col md='12' className='mb-4'>
                                    <Label className='form-label' for='postCode'>Business Logo</Label>
                                    <div className="d-flex">
                                        <div className="me-25">
                                            <img className="rounded me-50" src={renderFileSize()} alt="Business Logo" onError={showImage} />
                                        </div>
                                        <div className="d-flex align-items-end mt-75 ms-1">
                                            <div {...getRootProps()}>
                                                <Button className="ms-2" size="sm" color="primary">Upload</Button>
                                                <input {...getInputProps()} />
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Label className='form-label' for='postCode'>Tax Settings</Label>
                                <Col md='12' className='my-2'>
                                    <Input type="checkbox" className="me-4" checked={isChargeVat} onChange={(e) => setIsChargeVat(e.target.checked)} />
                                    <Label className='form-label' for='postCode'>Charge VAT</Label>
                                </Col>
                                <Col md='12' className='mb-4 d-flex align-items-center'>
                                    <Controller name='vat_rate' control={control} rules={{ required: false }} defaultValue=''
                                        render={({ field }) => (
                                            <Input placeholder="VAT" type="number" disabled={!isChargeVat} className="me-5" style={{ width: '150px' }} {...field} />
                                        )}
                                    />
                                    <Label className='form-label' for='postCode'>VAT rate</Label>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Label className='form-label' for='postCode'>Default Working Hours</Label>
                                <div className="d-flex">
                                    <div className="me-5">
                                        <Label className='form-label' for='postCode'>Start</Label>
                                        <Controller name='start' control={control} rules={{ required: false }} defaultValue=''
                                            render={({ field }) => (
                                                <Input type="time" style={{ width: '100px' }} {...field} />
                                            )}
                                        />
                                    </div>
                                    <div>
                                        <Label className='form-label' for='postCode'>End</Label>
                                        <Controller name='end' control={control} rules={{ required: false }} defaultValue=''
                                            render={({ field }) => (
                                                <Input type="time" style={{ width: '100px' }} {...field} />
                                            )}
                                        />
                                    </div>
                                </div>
                            </Row>
                            <div className='d-flex justify-content-end mt-10'>
                                <div>
                                    <Button type='submit' color='primary' disabled={loader === 'update'} className='btn-next'>
                                        {loader === 'update' ? <Spinner size='sm' /> : <span className='align-middle d-sm-inline-block d-none'>Save</span>}
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    </CardBody>
                </UILoader>
            </Card>
        </Fragment>
    )
}

export default EntityProfile