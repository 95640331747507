import React, { useEffect, useState } from 'react'
import { ModalHeader } from 'react-bootstrap'
import { Button, Input, Label, Modal, ModalBody, ModalFooter, Row, Spinner } from 'reactstrap'
import { JobService } from '../../modules/Services/Job'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router'
import Flatpickr from 'react-flatpickr'
import moment from 'moment'

function CopyBookingModal({ id, bookingData, openCopyModal, setOpenCopyModal }) {

    const defaultData = {
        job_name: bookingData?.job_name ? bookingData?.job_name + ' (copy)' : '',
        jobs: false,
        resources: false,
        start_date: null,
        end_date: null,
        start_job_from: null
    }

    const entity_id = localStorage.getItem('entity_id')
    const [copyBooking, setCopyBooking] = useState(defaultData)
    const [loader, setLoader] = useState(false)
    const navigate = useNavigate()

    const handleCopyBooking = (field: any, value: any) => {
        setCopyBooking((pre) => ({ ...pre, [field]: value }))
    }

    const submitCopyJobs = () => {
        setLoader(true)

        const data = {
            job_name: copyBooking?.job_name,
            is_copy_jobs: copyBooking?.jobs,
            is_copy_resources: copyBooking?.resources,
            start_at_date: copyBooking?.start_date,
            end_at_date: copyBooking?.end_date,
            start_job_from: copyBooking?.start_job_from
        }
        JobService.copyJobs(entity_id, id, data)
            .then((res: any) => {
                toast.success(res?.data?.message)
                setOpenCopyModal(false)
                navigate(`/booking`)
                setTimeout(() => {
                    navigate(`/booking/${res?.data?.response?.id}/edit`)
                }, 100);
            })
            .catch((err: any) => toast.error(err?.response?.data?.message))
            .finally(() => setLoader(false))
    }

    useEffect(() => {
        if (openCopyModal) {
            setCopyBooking(defaultData)
        }
    }, [openCopyModal])

    return (
        <Modal isOpen={openCopyModal} toggle={() => setOpenCopyModal(false)} className={`modal-dialog-centered modal-md`}>
            <ModalHeader className='d-flex justify-content-center'>
                <span style={{ fontSize: '19px' }}>Copy Booking</span>
            </ModalHeader>
            <ModalBody>
                <Row>
                    <div>
                        <span style={{ fontSize: '14px' }}>Are you sure you wish to copy booking ID {bookingData?.booking_number}?</span>
                    </div>
                    <div className='mt-5 w-50'>
                        <Label className='mb-0'>Copy Booking Name</Label>
                        <Input type="text"
                            placeholder='Enter Copy Booking Name'
                            value={copyBooking?.job_name}
                            onChange={(e) => handleCopyBooking('job_name', e.target.value)} />
                    </div>
                    <div className='mt-5 d-flex align-items-center'>
                        <Input type="checkbox"
                            checked={copyBooking?.jobs}
                            onChange={(e) => handleCopyBooking('jobs', e.target.checked)} />
                        <Label className='ms-5 mb-0'>Copy Jobs</Label>
                    </div>
                    <div className='mt-5 d-flex align-items-center'>
                        <Input type="checkbox"
                            checked={copyBooking?.resources}
                            onChange={(e) => handleCopyBooking('resources', e.target.checked)} />
                        <Label className='ms-5 mb-0'>Copy Resources</Label>
                    </div>
                    {bookingData?.job_types == 1 ?
                        <div className='mt-5 w-50'>
                            <Label className='form-label'>Start Jobs from</Label>
                            <Flatpickr
                                className='form-control'
                                placeholder='Select Date'
                                options={{
                                    enableTime: false,
                                    altInput: true,
                                    dateFormat: 'Y-m-d H:i',
                                    disableMobile: true
                                }}
                                value={copyBooking?.start_job_from}
                                onChange={(data: any) => {
                                    handleCopyBooking('start_job_from', moment(data[0]).format('YYYY-MM-DD'))
                                }}
                            />
                        </div>
                        :
                        <div className='mt-5 d-flex'>
                            <div className='me-5 w-50'>
                                <Label className='form-label'>Start</Label>
                                <Flatpickr
                                    className='form-control'
                                    placeholder='Select Date'
                                    options={{
                                        enableTime: false,
                                        altInput: true,
                                        dateFormat: 'Y-m-d H:i',
                                        disableMobile: true
                                    }}
                                    value={copyBooking?.start_date}
                                    onChange={(data: any) => {
                                        handleCopyBooking('start_date', moment(data[0]).format('YYYY-MM-DD'))
                                        handleCopyBooking('end_date', moment(data[0]).format('YYYY-MM-DD'))
                                    }}
                                />
                            </div>
                            <div className='w-50'>
                                <Label className='form-label'>End</Label>
                                <Flatpickr
                                    className='form-control'
                                    placeholder='Select Date'
                                    options={{
                                        enableTime: false,
                                        altInput: true,
                                        dateFormat: 'Y-m-d H:i',
                                        disableMobile: true
                                    }}
                                    value={copyBooking?.end_date}
                                    onChange={(data: any) => {
                                        handleCopyBooking('end_date', moment(data[0]).format('YYYY-MM-DD'))
                                    }}
                                />
                            </div>
                        </div>
                    }
                </Row>
            </ModalBody>
            <ModalFooter>
                <div>
                    <Button type="button" color="secondary" className="me-3 btn-sm" onClick={() => setOpenCopyModal(false)}>
                        Cancel
                    </Button>
                    <Button type='submit' color='primary' className='btn-sm' disabled={loader} onClick={submitCopyJobs}>
                        {loader ? <Spinner size='sm' /> : <span className='align-middle d-sm-inline-block d-none'>Copy</span>}
                    </Button>
                </div>
            </ModalFooter>
        </Modal>
    )
}

export default CopyBookingModal